import { useEffect, useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@material-ui/core';
import {
  createAccountMutation,
  createAccountWithAragornMutation
} from 'graphqlQueries/createAccount';
import {
  updateAccountMutation,
  updateAccountWithAragornMutation
} from 'graphqlQueries/updateAccount';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OperationResult, useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { editUserAdapter } from 'views/ServicesHub/adapters/edit/user';
import { newUserAdapter } from 'views/ServicesHub/adapters/new/user';
import { LayoutType } from 'views/ServicesHub/forms';
import { useDefaultUser } from 'views/ServicesHub/forms/User/utils/useDefaultUser';

import { User } from '../../adapters/new/user';

import FormSkeleton from './components/FormSkeleton';
import { createUserSchema, editUserSchema } from './schemas';
import { useStyles } from './styles';
import { useStartValues } from './useStartValues';

export function UserLayout({ Form, isEdit }: { Form: any; isEdit: LayoutType['isEdit'] }) {
  const hasNewUserPermission = usePermission('UserController-post-/users');
  const hasUpdateUserPermission = usePermission('UserController-put-/users/:id/account');

  const hasPermissionToCreate = usePermission('AccountController-post-/accounts');
  const hasPermissionToUpdate = usePermission('AccountController-put-/accounts');

  const shouldUseAccountViaAragorn = useFeatureFlagUnleash('useAccountViaAragorn', {
    queryString: true
  });

  const [hasInvalidEmail, setHasInvalidEmail] = useState(true);

  const classes = useStyles();
  const { startValues, fetching: fetchingStartValues } = useStartValues();
  const { defaultUser, defaultRole, organizationRoles } = useDefaultUser();
  const { uid: userId } = useParams<{ uid: string }>();

  const form = useForm({
    mode: 'all',
    resolver: zodResolver(isEdit ? editUserSchema : createUserSchema),
    defaultValues: {
      users: [defaultUser] as any
    }
  });

  const { reset: formReset, getValues } = form;

  const dispatch = useDispatch();

  const history = useHistory();

  const [{ fetching: loadingCreateUser }, createUser] = useMutation(createAccountMutation);
  const [{ fetching: loadingCreateUserWithAragorn }, createUserWithAragorn] = useMutation(
    createAccountWithAragornMutation
  );

  const [{ fetching: loadingUpdateUser }, updateUser] = useMutation(updateAccountMutation);
  const [{ fetching: loadingUpdateUserWithAragorn }, updateUserWithAragorn] = useMutation(
    updateAccountWithAragornMutation
  );

  const dispatchResult = ({
    operation,
    response
  }: {
    operation: string;
    response: OperationResult<object>;
  }) => {
    const errorMessage = operation === 'create' ? 'Error on create user' : 'Error on update user';
    const successMessage =
      operation === 'create' ? 'User created successfully' : 'User updated successfully';

    if (response?.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: errorMessage }
      });

      if (response.error) apm.captureError(response.error);
      return;
    }

    dispatch({
      payload: successMessage,
      type: actions.GLOBAL_SUCCESS
    });

    history.push(`/users`);
  };

  const handleCreate = async ({ data }: { data: { users: User[] } }) => {
    const createAccountInputs = newUserAdapter(data, defaultRole!.id);

    const response = shouldUseAccountViaAragorn
      ? await createUserWithAragorn({
          createAccountWithAragornInputs: createAccountInputs
        })
      : await createUser({ createAccountInputs });

    dispatchResult({ operation: 'create', response });
  };

  const handleUpdate = async ({ data }: { data: { users: User[] } }) => {
    const payload = editUserAdapter({ users: [{ ...data?.users?.[0] }] });
    const { users } = getValues();

    const response = shouldUseAccountViaAragorn
      ? await updateUserWithAragorn(
          {
            updateAccountWithAragornInputs: { ...payload, userId: Number(userId) }
          },
          injectAuditTrailMetadata({ userName: users[0].name!, userId: userId })
        )
      : await updateUser(
          {
            updateAccountInputs: { ...payload, userId: Number(userId) }
          },
          injectAuditTrailMetadata({ userName: users[0].name!, userId: userId })
        );

    dispatchResult({ operation: 'update', response });
  };

  const handleSubmit = async (data: { users: User[] }) => {
    if (isEdit && startValues) {
      handleUpdate({ data });
      return;
    }

    handleCreate({ data });
  };

  const fetching =
    loadingCreateUserWithAragorn ||
    loadingCreateUser ||
    fetchingStartValues ||
    loadingUpdateUser ||
    loadingUpdateUserWithAragorn;

  useEffect(() => {
    if ((isEdit && startValues) || (!isEdit && defaultUser.role >= 0))
      formReset({
        users: [isEdit ? startValues : defaultUser] as any
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset, isEdit, defaultRole, startValues, fetching]);

  const watchStatus = form.watch('users.0.status');
  const watchRole = form.watch('users.0.role');

  const shouldDisableSaveButton =
    startValues?.status === watchStatus && startValues?.role === watchRole;

  const disableButton = isEdit
    ? shouldDisableSaveButton || !form.formState.isValid
    : !form.formState.isValid || hasInvalidEmail;

  if (!isEdit && (hasNewUserPermission === false || hasPermissionToCreate === false)) {
    history.push(`/forbidden`);
  }

  if (isEdit && (hasUpdateUserPermission === false || hasPermissionToUpdate === false)) {
    history.push(`/forbidden`);
  }

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {organizationRoles && fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Basic informations
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            {!!defaultUser?.role ? (
              <Form
                form={form}
                isEdit={isEdit}
                organizationRoles={organizationRoles}
                defaultUser={defaultUser}
                startValues={startValues}
                setHasInvalidEmail={setHasInvalidEmail}
              />
            ) : (
              <FormSkeleton />
            )}
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Manage User Info
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Provide the necessary details for each user you want to add, including their Email,
              Name, and Role. You can create one or multiple users in a single process. After
              setting up the users, you can edit their Role if their responsibilities change and
              update their status to indicate whether they are Active or Inactive within the
              organization. This setup allows for flexible and efficient user management.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={disableButton}>
          {isEdit ? 'Save' : 'Create'} user
        </Button>
      </Box>
    </Box>
  );
}
