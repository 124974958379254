import { useState, useEffect } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CreateProviderMutation } from 'graphqlQueries/createProvider';
import { UpdateProviderMutation } from 'graphqlQueries/updateProvider';
import { DialogTitle, DialogContent } from 'oldComponents/LegacyDialog';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { ProvidersEnum } from 'hooks/queriesGraphQL/useGetProvider';
import actions from 'redux/actions';

import { LayoutType } from '../forms';
import { FormatConfig } from '../forms/mapToApi';

import providerSchema from './providerSchema';
import { useStyles } from './styles';

export type ProviderBaseForm = {
  id: number;
  teamId: number;
  providerName: ProvidersEnum;
  enabled: boolean;
  config: {};
};

export const ProviderLayout = ({
  Form,
  title,
  startValues,
  isEdit,
  onClose,
  reexecuteQuery,
  hasPermissionCreate,
  hasPermissionUpdate
}: LayoutType) => {
  const classes = useStyles();
  const routeParams = useParams<{ id: string }>();
  const [hasLoaded, setHasLoaded] = useState(false);

  const defaultValues = {
    teamId: 0,
    enabled: true,
    config: {}
  };

  const form = useForm<any>({
    mode: 'all',
    resolver: zodResolver(providerSchema),
    defaultValues
  });

  const { reset } = form;

  useEffect(() => {
    if (isEdit && !hasLoaded) {
      reset(startValues);
      setHasLoaded(true);
    }
  }, [reset, startValues, isEdit, hasLoaded]);

  const dispatch = useDispatch();

  const [{ fetching: creatingProvider }, createProvider] = useMutation(CreateProviderMutation);
  const [{ fetching: updatingProvider }, updateProvider] = useMutation(UpdateProviderMutation);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (data: ProviderBaseForm) => {
    if (isEdit) {
      const providerUpdateResponse = await updateProvider(
        {
          data: {
            id: startValues?.id,
            teamId: Number(routeParams.id),
            providerName: startValues?.providerName,
            enabled: true,
            config: FormatConfig(data.config, startValues?.providerName)
          }
        },
        injectAuditTrailMetadata({
          providerName: startValues ? startValues.providerName : '',
          id: startValues ? startValues.id.toString() : ''
        })
      );

      if (providerUpdateResponse.error) {
        setSaving(false);
        handleClose();
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update provider' }
        });

        // eslint-disable-next-line no-console
        console.error(providerUpdateResponse.error);
        apm.captureError(providerUpdateResponse.error);
        reexecuteQuery();
        return;
      }

      setSaving(false);
      handleClose();
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Provider update successfully'
      });

      reexecuteQuery();
      return;
    }

    const providerCreateResponse = await createProvider({
      data: {
        teamId: Number(routeParams.id),
        providerName: startValues?.providerName,
        enabled: true,
        config: FormatConfig(data.config, startValues?.providerName)
      }
    });

    if (providerCreateResponse.error) {
      setSaving(false);
      handleClose();
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create provider' }
      });

      // eslint-disable-next-line no-console
      console.error(providerCreateResponse.error);
      apm.captureError(providerCreateResponse.error);
      reexecuteQuery();
      return;
    }

    setSaving(false);
    handleClose();
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Provider create successfully'
    });

    reexecuteQuery();
    return;
  };

  const handleClose = () => {
    onClose();
  };

  const fetching = creatingProvider || updatingProvider;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      onSubmit={form.handleSubmit(handleSubmit)}
      position="relative">
      <Box>
        {(fetching || saving) && <LoadingOverlay />}
        <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h5">Integration with {title}</Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Form form={form} startValues={startValues} />

          <Box paddingTop={1} display="flex" justifyContent="flex-end" className={classes.buttons}>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              style={{
                marginLeft: '1rem',
                whiteSpace: 'nowrap'
              }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isEdit ? !hasPermissionUpdate : !hasPermissionCreate}>
              Save
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Box>
  );
};
