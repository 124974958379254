import * as z from 'zod';

export default z.object({
  config: z.union([
    z.object({
      email: z.string().email()
    }),
    z.object({
      telegramId: z.string()
    }),
    z.object({
      slackIntegration: z.any(),
      slackWorkspace: z.string(),
      slackChannel: z.string()
    }),
    z.object({
      teamsWebhookUrl: z.string()
    }),
    z.object({
      gchatWebhookUrl: z.string()
    }),
    z.object({
      discordChannelWebhookUrl: z.string()
    }),
    z.object({
      webhookUrl: z.string(),
      webhookRetryFailEmail: z.string(),
      webhookRetry: z.string()
    })
  ])
});
