import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(12),
    backgroundColor: theme.palette.background.default,
    paddingRight: theme.spacing(13),
    paddingLeft: theme.spacing(13)
  },
  paper: {
    padding: theme.spacing(2, 2),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    borderRadius: 8
  },
  cardContent: {
    width: '100%',
    height: '100%',
    padding: 0
  },
  titleCard: {
    marginBottom: theme.spacing(2),
    fontSize: 16
  },
  dividerSections: {
    opacity: '30%',
    backgroundColor: theme.palette.text.hint
  },
  noData: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  scrollBar: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 120,
    height: '100%',
    overflowY: 'auto',
    scrollbarWidth: 'auto',
    paddingTop: theme.spacing(),
    scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.grey[100]}`,
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5)
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[100]
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.grey[300]
    }
  }
}));
