import React from 'react';

import ApplicationType from 'constants/ApplicationType';
import { postBodyTypeValues } from 'constants/postBody';

import { Box, Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { useQuery as useQueryUrql } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { useProductPermission } from 'hooks/useProductPermission';
import axios from 'redux/axios';

import MaintenanceEditDialog from '../../../components/Maintenance/Dialog/MaintenanceEditDialog';
import ResourceEditDialog from '../../../components/Resources/Dialog/ResourceEditDialog';
import { ScheduledEventDialog } from '../../../components/ScheduledEvent/Dialog/ScheduledEventDialog';
import MonitoringStatus from '../../components/MonitoringStatus';
import Buttons from '../Buttons';

import { getTeamsByResourceId } from './../../queries/getTeamsByResourceId';
import Info from './Info';

const useStyles = makeStyles(({ spacing }) => ({
  outMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#F57D27',
    borderRadius: 20,
    marginLeft: spacing(-1),
    marginBottom: spacing(2)
  },
  openingHoursMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#3CB371',
    borderRadius: 20,
    marginLeft: spacing(-1),
    marginBottom: spacing(2)
  },
  container: {
    flex: 1,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    padding: spacing(1, 2, 2)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing()
  },
  informations: {
    display: 'flex',
    alignItems: 'center'
  },
  gutterTop: {
    marginTop: spacing()
  },
  badge: {
    display: 'block',
    borderRadius: 20,
    backgroundColor: 'green',
    border: '1px solid #000000',
    width: 16,
    height: 16,
    marginRight: spacing()
  }
}));

const EditButton = props => (
  <IconButton title="Edit Application" type="submit" disabled={props.disabled} {...props}>
    <Icon>edit</Icon>
  </IconButton>
);

function ApplicationCardInfo({
  application,
  applicationStatusUniqueResource,
  monitoringActive,
  productId,
  applicationId,
  selectAgentType,
  lastCheck,
  addonHttpFlow,
  onUpdateAddon,
  previousDayDate,
  maintenance,
  submitDeleteMaintenance,
  reexecuteQueryUniqueResourceStatus,
  monitoringStatus,
  addons,
  applicationUid
}) {
  const classes = useStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const isHttpFlow =
    application.applicationsSettings?.type === ApplicationType.InternalMultiHttp.type
      ? true
      : false;

  const { data: product } = useQuery(
    ['products', productId],
    () => axios.get(`/products/${productId}`).then(response => response.data.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const permissions = {
    edit: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-put-/products/:productId/applications/:id'
    }),
    remove: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-delete-/products/:productId/applications/:id'
    }),
    activateMonitoring: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-post-/products/:productId/applications/:id/monitoring/activate'
    }),
    deactivateMonitoring: useProductPermission({
      productId: String(product?.id),
      target:
        'ApplicationController-delete-/products/:productId/applications/:id/monitoring/deactivate'
    }),
    deploy: useProductPermission({
      productId: String(product?.id),
      target: 'TimelineController-post-/products/:productId/applications/:applicationId/timeline'
    }),
    maintenance: useProductPermission({
      productId: String(product?.id),
      target: 'MaintenanceController-post-/maintenance'
    }),
    scheduled: useProductPermission({
      productId: String(product?.id),
      target: 'ScheduledEventController-post-/scheduledEvent'
    }),
    forceMaintenanceFinish: useProductPermission({
      productId: String(product?.id),
      target: 'MaintenanceController-put-/maintenance/force_finish'
    })
  };

  const [getQueryFromMsTeams, reexecuteQueryFromMsTeams] = useQueryUrql({
    query: getTeamsByResourceId,
    variables: {
      resourceId: Number(applicationId)
    }
  });

  const team = {
    name: getQueryFromMsTeams?.data?.getTeamsByResourceId[0]?.name,
    id: getQueryFromMsTeams?.data?.getTeamsByResourceId[0]?.resourceTeams[0]?.teamId
  };

  const reexecuteQuery = async () => {
    await reexecuteQueryUniqueResourceStatus();
    await reexecuteQueryFromMsTeams();
  };

  const neverMonitored =
    application?.sterasId === null || application?.sterasId === 0 ? true : false;

  const activeMaintenanceButton =
    permissions.maintenance || (permissions.forceMaintenanceFinish && maintenance?.id > 0);

  const [, setMaintenanceActivated] = React.useState(false);

  const MaintenanceButton = React.useMemo(
    () => props => {
      return (
        <div
          title={
            neverMonitored
              ? "Can't include maintenance because the application never monitoring"
              : 'Maintenance window'
          }>
          <IconButton
            disabled={!monitoringActive || !activeMaintenanceButton}
            type="submit"
            {...props}>
            <Icon>construction</Icon>
            <Box className={clsx(maintenance?.id && classes.outMarginPoint)} />
          </IconButton>
        </div>
      );
    },
    [
      neverMonitored,
      monitoringActive,
      activeMaintenanceButton,
      maintenance?.id,
      classes.outMarginPoint
    ]
  );

  const [openingHoursActivated, setOpeningHoursActivated] = React.useState(false);

  const ScheduledEventButton = React.useMemo(
    () => props => {
      return (
        <div
          title={
            neverMonitored
              ? "Can't use the working window because the application never monitoring"
              : 'The application is running in default mode, click to set an opening hours'
          }>
          <IconButton type="submit" {...props} disabled={neverMonitored || !permissions.scheduled}>
            <Icon>edit_calendar</Icon>
            <Box className={clsx(openingHoursActivated && classes.openingHoursMarginPoint)} />
          </IconButton>
        </div>
      );
    },
    [openingHoursActivated, classes.openingHoursMarginPoint, neverMonitored, permissions.scheduled]
  );

  const canUseMaintenanceWindowFeaturePlan = useFeaturePlan('MaintenanceWindow');
  const canUseOpeningHoursFeaturePlan = useFeaturePlan('OpeningHours');

  return (
    <Grid sm={4} xs={12} item className={classes.container}>
      <Box className={classes.header}>
        {application?.id && (
          <MonitoringStatus
            serviceId={application?.sterasId}
            application={application}
            applicationStatusUniqueResource={applicationStatusUniqueResource}
            monitoringStatus={monitoringStatus}
          />
        )}

        <Box display="flex">
          <Box display="flex" mr={2}>
            {canUseOpeningHoursFeaturePlan && (
              <ScheduledEventDialog
                id="scheduledEventIcon"
                openerComponent={props => (
                  <ScheduledEventButton disabled={!permissions.edit} {...props} />
                )}
                applicationName={application.name}
                serviceId={application?.sterasId}
                applicationId={application?.id}
                resourceUid={''}
                entity={'service'}
                setActivated={setOpeningHoursActivated}
                uid={applicationUid}
              />
            )}
            {canUseMaintenanceWindowFeaturePlan && (
              <MaintenanceEditDialog
                id="editMaintenanceIcon"
                openerComponent={MaintenanceButton}
                applicationName={application.name}
                serviceId={application?.sterasId}
                applicationId={application?.id}
                uid={''}
                entity={'service'}
                setActivated={setMaintenanceActivated}
                reexecuteEntityQuery={reexecuteQueryUniqueResourceStatus}
              />
            )}
          </Box>

          {isHttpFlow && !addonHttpFlow?.length ? null : (
            <ResourceEditDialog
              id="editAppIcon"
              openerComponent={props => <EditButton disabled={!permissions.edit} {...props} />}
              applicationName={application.name}
              applicationType={application.applicationsSettings?.type}
              applicationId={application.id}
              application={application}
              productId={productId}
              teamId={team?.id}
              onUpdateAddon={onUpdateAddon}
              addonHttpFlow={addonHttpFlow}
              reexecuteQueryUniqueResourceStatus={reexecuteQuery}
              postBodyRawJson={postBodyTypeValues}
              maxWidth={
                application.applicationsSettings?.type === ApplicationType.Webhook.type
                  ? 'sm'
                  : undefined
              }
            />
          )}
          <IconButton
            id={`removeAppIcon`}
            title="Remove Application"
            disabled={!permissions.remove || !applicationId}
            onClick={() => setIsDeleteModalOpen(true)}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
      </Box>

      <Info
        team={team}
        application={application}
        monitoringActive={monitoringActive}
        selectAgentType={selectAgentType}
        lastCheck={lastCheck}
        productId={productId}
        applicationId={applicationId}
        previousDayDate={previousDayDate}
      />

      <Buttons
        application={application}
        monitoringActive={monitoringActive}
        isDeleteModalOpen={isDeleteModalOpen}
        reexecuteQueryUniqueResourceStatus={reexecuteQueryUniqueResourceStatus}
        handleCloseDeleteModal={() => setIsDeleteModalOpen(false)}
        maintenance={maintenance}
        submitDeleteMaintenance={submitDeleteMaintenance}
        monitoringStatus={monitoringStatus}
        addons={addons}
      />
    </Grid>
  );
}

export default ApplicationCardInfo;
