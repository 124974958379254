import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import { useSubscription } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import { useIncidentState } from 'hooks/queriesGraphQL/useIncidentState';
import { useServiceMonitors } from 'hooks/queriesGraphQL/useServiceMonitors';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { AvgLatencyMetrics } from './components/AvgLatencyMetrics';
import { DowntimeMetrics } from './components/DowntimeMetrics';
import { LastEvents } from './components/Event';
import { IncidentsTotal } from './components/IncidentsTotal';
import { IncidentTotalResponseEffortMetrics } from './components/IncidentTotalResponseEffortMetrics';
import { MaxLatencyMetrics } from './components/MaxLatencyMetrics';
import { MinLatencyMetrics } from './components/MinLatencyMetrics';
import { MTTA } from './components/MTTA';
import { MTTAByResponderMetric } from './components/MTTAByResponderMetrics';
import { MTTR } from './components/MTTR';
import { MTTRByResponderMetrics } from './components/MTTRByResponderMetrics';
import { OutagesMetrics } from './components/OutagesMetrics';
import { ResponderIncidentVolume } from './components/ResponderIncidentVolumeMetrics';
import { UptimeMetrics } from './components/UptimeMetrics';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useStyles } from './styles';

export function HomeView() {
  const classes = useStyles();
  const theme = useTheme();

  const firstLineNewHome = useFeatureFlagUnleash('firstLineNewHome', { queryString: true });
  const secondLineNewHome = useFeatureFlagUnleash('secondLineNewHome', { queryString: true });
  const thirdLineNewHome = useFeatureFlagUnleash('thirdLineNewHome', { queryString: true });

  const {
    data: alerts,
    fetching: isFetchingAlerts,
    reexecuteQuery: reexecuteQueryAlerts
  } = useAlertsState({
    from: 1,
    size: 5
  });

  const {
    data: { incidents },
    fetching: isFetchingIncidents,
    reexecuteQuery: reexecuteQueryIncidents
  } = useIncidentState({
    page: 1,
    perPage: 5,
    query: '',
    betweenKey: 'failure_happened_at',
    betweenValues: 'now-1y,now',
    pause: !HomeView
  });

  const [{ data: monitors, fetching: isFetchingMonitors }] = useServiceMonitors({
    query: `(type: multiflow) OR (type: resource)`
  });

  const isFetchingHome = isFetchingIncidents || isFetchingAlerts || isFetchingMonitors;
  const existsMonitors = monitors?.getMonitorsService.data.length ? true : false;
  const showFirstLine = firstLineNewHome && !isFetchingHome && existsMonitors;

  useSubscription<void>(
    {
      query: `
        subscription {
          incidentsCreated {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryIncidents();
    }
  );

  useSubscription<void>(
    {
      query: `
        subscription {
          alertsState {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryAlerts();
    }
  );

  if (isFetchingHome) {
    return (
      <LoadingSkeleton firstLineNewHome={firstLineNewHome} secondLineNewHome={secondLineNewHome} />
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
        {showFirstLine && (
          <Grid container spacing={2}>
            <Grid item lg={2} md={4} xs={12}>
              <UptimeMetrics />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <DowntimeMetrics />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <OutagesMetrics />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <MaxLatencyMetrics />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <MinLatencyMetrics />
            </Grid>
            <Grid item lg={2} md={4} xs={12}>
              <AvgLatencyMetrics />
            </Grid>
          </Grid>
        )}
        {secondLineNewHome && (
          <Grid container spacing={2}>
            <Grid item lg={3} md={6} xs={12}>
              <IncidentsTotal />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <IncidentTotalResponseEffortMetrics />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MTTA />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <MTTR />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper elevation={1} className={classes.paperTop} variant="outlined">
              <Typography variant="subtitle2" className={classes.paperTitle}>
                Last Incidents
              </Typography>
              {isFetchingIncidents ? (
                <LoadingOverlay isBlock />
              ) : incidents.length > 0 ? (
                incidents.map(incident => (
                  <>
                    <LastEvents
                      id={incident?.incidentId}
                      severity={incident.severity}
                      status={incident.status}
                      title={incident.title}
                      to={`/incidents/${incident.incidentId}`}
                      happenedAt={incident.failureHappenedAt}
                      updatedAt={incident.updatedAt}
                    />
                  </>
                ))
              ) : (
                <Typography variant="h6">No incidents found</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={1} className={classes.paperTop} variant="outlined">
              <Typography variant="subtitle2" className={classes.paperTitle}>
                Last Alerts
              </Typography>
              {isFetchingAlerts ? (
                <LoadingOverlay isBlock />
              ) : Boolean(alerts?.alertsState?.total) ? (
                alerts?.alertsState?.data?.map(alert => (
                  <>
                    <LastEvents
                      id={alert.id}
                      severity={alert.severity}
                      status={alert.status}
                      title={alert.title}
                      to={`/alert-center?ids=${alert.id}`}
                      happenedAt={alert.alertHappenedAt}
                      updatedAt={alert.updatedAt}
                    />
                  </>
                ))
              ) : (
                <Typography variant="h6">No alerts found</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
        {thirdLineNewHome && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ResponderIncidentVolume />
            </Grid>

            <Grid item xs={4}>
              <MTTAByResponderMetric />
            </Grid>

            <Grid item xs={4}>
              <MTTRByResponderMetrics />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
