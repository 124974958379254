import { useQuery } from 'urql';

export type IncidentResponders = {
  id: number;
  incidentId: number;
  name: string;
  type: string;
};

const GetIncidentRespondersQuery = `#graphql
    query ($incidentId: Int!) {
      getIncidentResponders(incidentId: $incidentId) {
        id
        incidentId
        name
        type
      }
    }
  `;

export function useGetIncidentResponders(incidentId: number) {
  const [{ data, fetching }] = useQuery<{ getIncidentResponders: IncidentResponders[] }>({
    query: GetIncidentRespondersQuery,
    variables: { incidentId }
  });

  return { data: data?.getIncidentResponders ?? [], isLoading: fetching };
}
