import React from 'react';

import {
  Divider,
  Box,
  IconButton,
  TextField,
  Card,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { CardHeader } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useMutation } from 'urql';

import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useCopy from 'hooks/useCopy';

import RemoveConfirmation from './RemoveConfirmation';

const RemoveSecretMutation = `
  mutation($name: String!) {
    removeSecret(removeSecretData: { name: $name})
  }
`;

const SecretItem = ({ permissions, secret, onSecretRemove }) => {
  const [removeSecretResult, removeSecret] = useMutation(RemoveSecretMutation);

  const [removeConfirmationDialogOpen, setRemoveConfirmationDialogOpen] = React.useState(false);

  const handleRemove = () => {
    setRemoveConfirmationDialogOpen(true);
  };

  const handleRemoveConfirm = () => {
    removeSecret({ name: secret.name }, injectAuditTrailMetadata({ name: secret.name })).then(
      data => {
        if (!data.error) {
          onSecretRemove();
        }
      }
    );
    setRemoveConfirmationDialogOpen(false);
  };

  const handleRemoveCancel = () => {
    setRemoveConfirmationDialogOpen(false);
  };

  const { handleCopy, copiedSuccessfully } = useCopy({ copiedSuccessfullyTimeout: 2000 });

  const usageExample = `{{.${secret.name}}}`;

  const removeButton = (
    <IconButton
      disabled={!permissions.remove}
      title="Remove secret"
      aria-label="remove secret"
      onClick={handleRemove}>
      <DeleteIcon />
    </IconButton>
  );

  return (
    <Card style={{ position: 'relative' }}>
      <CardHeader
        title={secret.name}
        action={
          !permissions.remove ? (
            <Tooltip title="You don't have permission to remove this item">
              <span>{removeButton}</span>
            </Tooltip>
          ) : (
            removeButton
          )
        }
      />

      <RemoveConfirmation
        open={removeConfirmationDialogOpen}
        isLoading={removeSecretResult.fetching}
        onCancel={handleRemoveCancel}
        onConfirm={handleRemoveConfirm}
        title="Remove secret">
        Do you really want to remove this secret?
      </RemoveConfirmation>

      <Divider />
      <CardContent>
        <TextField
          fullWidth
          label="Use secret"
          id={secret.name}
          value={copiedSuccessfully ? 'Copied successfully' : usageExample}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  title="Copy secret reference for usage"
                  aria-label="copy secret reference for usage"
                  onClick={() => {
                    handleCopy(usageExample);
                  }}>
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          variant="outlined"
        />
      </CardContent>
      <Divider />
      {removeSecretResult.fetching && (
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgb(255 255 255 / 66%)',
            zIndex: 1000
          }}>
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
};

export default SecretItem;
