import { OperationContext } from 'urql';

import { getUrqlFetchOptions } from 'common/getUrqlFetchOptions';

export function injectAuditTrailMetadata(
  metadata: Record<string, string | string[]>
): Partial<OperationContext['fetchOptions']> {
  return {
    fetchOptions: {
      ...getUrqlFetchOptions(),
      headers: {
        'X-Platform-Audit-Trail-Metadata': btoa(encodeURIComponent(JSON.stringify(metadata)))
      }
    }
  };
}
