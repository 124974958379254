import { Typography, Box, Link, MenuItem, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab-old/Autocomplete';
import { Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Button from 'componentsV4/Button';
import Select from 'componentsV4/Select';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Accounts } from 'hooks/useGetAccounts';
import { Input } from 'views/ServicesHub/components/Input';

import useStyles from './styles';
import useAppActions from './useAppActions';
import useForm from './useForm';

const SignInSelect = ({ accounts }: { accounts: Accounts[] }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      orgId: '0'
    }
  });

  const options = accounts?.map((account: any) => {
    return (
      <MenuItem key={`option-org-${account.org.id}`} value={account.org.id}>
        {account.org.name}
      </MenuItem>
    );
  });

  const orgOptions = accounts?.map(account => {
    return {
      id: account.org.id,
      name: account.org.name
    };
  });

  const { handleSelectAccount, isLoading } = useAppActions();

  const newSelectOrg = useFeatureFlagUnleash('newSelectOrg', {
    queryString: true
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Box mb={2}>
          <Typography variant="h4">Which organization would you like to access?</Typography>
        </Box>

        <form name="signUpForm" onSubmit={handleSubmit(handleSelectAccount)}>
          {newSelectOrg ? (
            <Controller
              name="orgId"
              control={control}
              render={({ value, onChange }) => (
                <Autocomplete
                  value={orgOptions.find(option => option.id === value)}
                  onChange={(_, newValue) => onChange(newValue ? newValue.id : null)}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.id === value}
                  options={orgOptions}
                  renderInput={params => (
                    <Input {...params} label="Select organization" required={false} />
                  )}
                />
              )}
            />
          ) : (
            <Controller
              name="orgId"
              control={control}
              render={({ onChange, ...props }) => {
                return (
                  <Select
                    fullWidth
                    className={classes.select}
                    onChange={event => onChange(event)}
                    {...props}>
                    <MenuItem value="0" disabled>
                      Select organization
                    </MenuItem>
                    {options}
                  </Select>
                );
              }}
            />
          )}

          <Button
            type="submit"
            fullWidth
            text="Login"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!isValid}
          />
        </form>

        <Divider />

        <Typography variant="body1" className={classes.question}>
          Haven't created an organization yet?
        </Typography>

        <Link
          title="Create organization"
          to="/new_org"
          component={RouterLink}
          className={classes.link}>
          Create organization
        </Link>

        {isLoading && <LoadingOverlay />}
      </Box>
    </Box>
  );
};

export default SignInSelect;
