import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentGoogleCloudGuide = ({
  orgUid,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Google Cloud',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/googlecloud/${integrationUid}?token=${token}`,
    snippetWarning: {
      message: 'Need help or more information? Check out our ',
      linkHref:
        'https://docs.elven.works/guia-configuracao-do-external-service-incident-google-cloud-na-elven-platform/',
      linkText: 'documentation.'
    }
  };
};
