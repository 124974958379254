import { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { Divider, IconButton, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteAccountWithAragornMutation } from 'graphqlQueries/deleteAccount';
import { DeleteEntity as NewDeleteEntity } from 'oldComponents/DeleteDialog';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import { Title } from 'components/Title';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { useAccountList } from 'hooks/queriesGraphQL/useAccountList';
import { useRoles } from 'hooks/queriesGraphQL/useRoles';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { columns } from './columns';
import { Filters } from './components/Filters';

const PER_PAGE_OPTIONS = [10, 25, 50];

export enum StatusEnum {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive'
}

export enum RoleEnum {
  All = 'all',
  Admin = 'admin',
  Owner = 'owner'
}

export const Users = () => {
  const useNewActionsInUserList = useFeatureFlagUnleash('useNewActionsInUserList', {
    queryString: true
  });

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);
  const [dialogState, setDialogState] = useState({ isOpen: false, userId: 0, userName: '' });
  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<StatusEnum>(StatusEnum.All);
  const [roleFilter, setRoleFilter] = useState<RoleEnum>(RoleEnum.All);

  const history = useHistory();
  const dispatch = useDispatch();

  const composeStatusFilter = () => {
    switch (statusFilter) {
      case StatusEnum.Active:
        return true;
      case StatusEnum.Inactive:
        return false;
      default:
        return '';
    }
  };

  const { data: roles } = useRoles({});

  const {
    data: { accounts, total },
    fetching,
    reexecuteQuery
  } = useAccountList({
    page,
    perPage,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'name',
          value: search,
          isRegex: true
        },
        {
          key: 'active',
          value: composeStatusFilter(),
          isRegex: false
        },
        {
          key: 'role_id',
          value: roles.find(role => role.name === roleFilter)?.id.toString() || '',
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'accounts',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true',
    sortKey: 'name',
    sortValue: 'ASC'
  });

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const onEditAccount = (id: number) => {
    history.push(`/services-hub/edit/${id}?type=users`);
  };

  const onDeleteAccount = (id: number, name: string) => {
    setDialogState({ isOpen: true, userId: id, userName: name });
  };

  const permissions = {
    new: usePermission('AccountController-post-/accounts'),
    update: usePermission('AccountController-put-/accounts'),
    remove: usePermission('AccountController-delete-/accounts/:id')
  };

  const [, deleteAccountWithAragornMutation] = useMutation(DeleteAccountWithAragornMutation);

  const handleClose = () => {
    setDialogState({ isOpen: false, userId: 0, userName: '' });
  };

  const handleRemove = async () => {
    const response = await deleteAccountWithAragornMutation(
      { userId: dialogState.userId },
      injectAuditTrailMetadata({
        userName: dialogState.userName,
        userId: dialogState.userId.toString()
      })
    );

    if (response?.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on delete team' }
      });

      if (response.error) apm.captureError(response.error);
      return;
    }

    dispatch({
      payload: 'User deleted successfully',
      type: actions.GLOBAL_SUCCESS
    });

    reexecuteQuery();
    handleClose();
  };

  const deleteIcon = (id: number, name: string) => {
    return (
      <IconButton
        title="Remove"
        disabled={!Boolean(permissions?.remove)}
        onClick={() => onDeleteAccount(id, name)}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const editIcon = (id: number) => {
    return (
      <IconButton
        title="Edit"
        disabled={!Boolean(permissions?.update)}
        onClick={() => onEditAccount(id)}>
        <EditIcon />
      </IconButton>
    );
  };

  const accountColumns = columns({
    editIcon,
    deleteIcon,
    handleClickDeleteOption: (id: number, name: string) => onDeleteAccount(id, name),
    handleClickEditOption: (id: number) => onEditAccount(id),
    useNewActionsInUserList
  });

  return (
    <>
      <Breadcrumb items={[{ label: 'People' }, { link: `/users`, label: 'User Center' }]} />

      <Title title="User Center">
        <Button
          component={Link}
          to="/services-hub/new?type=users"
          title="Create new user"
          disabled={!Boolean(permissions.new)}>
          User
        </Button>
      </Title>

      <Filters
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        roleFilter={roleFilter}
        setRoleFilter={setRoleFilter}
        setSearch={setSearch}
        setPage={setPage}
      />

      {accounts?.length ? <Divider /> : null}

      <DataTable
        columns={accountColumns}
        data={accounts}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={total}
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={fetching}
        highlightOnHover
        pagination
        striped
      />

      <NewDeleteEntity
        open={dialogState?.isOpen}
        entityName="User"
        disabled={!permissions.remove}
        isLoading={false}
        handleClose={handleClose}
        handleRemove={handleRemove}
      />
    </>
  );
};
