import { useQuery } from 'urql';

type IncidentAlerts = {
  id: number;
  title: string;
  cause: string;
  severity: string;
  status: string;
  alertUid: string;
  incidentId: number;
};

const GetIncidentAlertsQuery = `#graphql
    query ($incidentId: Int!) {
      getIncidentAlerts(incidentId: $incidentId) {
        id
        title
        cause
        severity
        status
        alertUid
        incidentId
      }
    }
  `;

export function useGetIncidentAlerts(incidentId: number) {
  const [{ data, fetching }] = useQuery<{ getIncidentAlerts: IncidentAlerts[] }>({
    query: GetIncidentAlertsQuery,
    variables: { incidentId }
  });

  return { data: data?.getIncidentAlerts ?? [], isLoading: fetching };
}
