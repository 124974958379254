import { TableColumn } from 'react-data-table-component';
import { Account } from 'types/external/Account';

import { capitalizeFirstLetter } from 'views/ServicesHub/forms/User';

import { MenuActions } from './components/Menu/MenuActions';

export const columns = ({
  editIcon,
  deleteIcon,
  handleClickDeleteOption,
  handleClickEditOption,
  useNewActionsInUserList
}: {
  editIcon: (id: number) => void;
  deleteIcon: (id: number, name: string) => void;
  handleClickDeleteOption: (id: number, name: string) => void;
  handleClickEditOption: (id: number) => void;
  useNewActionsInUserList: boolean;
}): TableColumn<Account>[] => {
  return [
    {
      name: 'Name',
      cell: row => row?.name
    },
    {
      name: 'Email',
      cell: row => row?.email
    },
    {
      name: 'Status',
      cell: row => (row?.active ? 'Active' : 'Inactive')
    },
    {
      name: 'Role',
      cell: row => capitalizeFirstLetter(row.roleName)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      omit: useNewActionsInUserList,
      cell: (row: { userId: number }) => editIcon(row?.userId)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      omit: useNewActionsInUserList,
      cell: (row: { userId: number; name: string }) => deleteIcon(row?.userId, row.name)
    },
    {
      name: 'Actions',
      right: true,
      omit: !useNewActionsInUserList,
      cell: (row: { userId: number; name: string }) => (
        <MenuActions
          handleClickEditOption={() => handleClickEditOption(row?.userId)}
          handleClickDeleteOption={() => handleClickDeleteOption(row?.userId, row.name)}
        />
      )
    }
  ];
};
