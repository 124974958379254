import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  Link,
  Paper,
  Popper,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import dayjs from 'dayjs';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import { Tabs } from 'oldComponents/Tabs';
import { Link as RouterLink } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useSubscription } from 'urql';
import { v4 as uuid } from 'uuid';

import { reduceString } from 'helpers/reduceString';
import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import { useIncidentsState } from 'hooks/queriesGraphQL/useIncidentsState';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';
import { useIncidents } from 'views/IncidentCenter/useQuery/useIncidents';

import { useStylesV2 } from './styles';

dayjs.extend(dayjsRelativeTimePlugin);

const QUERY_SIZE = 5;
const CRITICAL_EVENTS_LIST_RENDER_POOL = 1000 * 30; // 30s

export function CriticalEvents({ children }: PropsWithChildren<{}>) {
  const classes = useStylesV2();

  const useIncidentStateOnCriticalEvents = useFeatureFlagUnleash(
    'useIncidentStateOnCriticalEvents',
    { queryString: true }
  );

  const [isCriticalEventsOpen, setIsCriticalEventsOpen] = useState(false);
  const [pageIncidents, setPageIncidents] = useState(1);
  const [pageAlerts, setPageAlerts] = useState(1);
  const [criticalEventsListKeyIncidents, setIsCriticalEventsKeyIncidents] = useState(uuid());
  const [criticalEventsListKeyAlerts, setIsCriticalEventsKeyAlerts] = useState(uuid());

  const criticalEventsContainerRef = useRef<HTMLDivElement>(null);

  const { data: dataIncidents, reexecuteQuery: reexecuteQueryIncidents } = useIncidents({
    perPage: QUERY_SIZE,
    page: pageIncidents,
    period: 0,
    severity: 'all',
    type: 'all',
    status: StatusEnum.Alarmed,
    query: '',
    pause: useIncidentStateOnCriticalEvents
  });

  const { data: dataAlerts, reexecuteQuery: reexecuteQueryAlerts } = useAlertsState({
    from: pageAlerts,
    size: QUERY_SIZE,
    query: `(status: ${StatusEnum.Alarmed})`
  });

  const {
    data: dataIncidentsState,
    reexecuteQuery: reexecuteQueryIncidentState
  } = useIncidentsState({
    from: pageIncidents,
    size: QUERY_SIZE,
    query: `(status: ${StatusEnum.Alarmed})`,
    pause: !useIncidentStateOnCriticalEvents
  });

  useSubscription<void>(
    {
      query: `
        subscription {
          incidentsCreated {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryIncidents();
    }
  );

  useSubscription<void>(
    {
      query: `
        subscription {
          alertsState {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryAlerts();
    }
  );

  useSubscription<void>(
    {
      query: `
        subscription {
          incidentsState {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryIncidentState();
    }
  );

  const hasDataAlerts = Boolean(dataAlerts?.alertsState?.total);
  const hasDataIncidents =
    Boolean(dataIncidents?.total) || Boolean(dataIncidentsState?.incidentsState?.total);

  const totalAlerts = dataAlerts?.alertsState?.total ?? 0;
  const totalIncidents = !useIncidentStateOnCriticalEvents
    ? dataIncidents?.total
    : dataIncidentsState?.incidentsState?.total ?? 0;

  const totalEvents = totalAlerts + totalIncidents;

  useEffect(() => {
    if (!isCriticalEventsOpen) return;

    const criticalEventsKeyUpdateTimeoutId = setInterval(() => {
      setIsCriticalEventsKeyIncidents(uuid());
      setIsCriticalEventsKeyAlerts(uuid());
    }, CRITICAL_EVENTS_LIST_RENDER_POOL);

    return () => clearTimeout(criticalEventsKeyUpdateTimeoutId);
  }, [isCriticalEventsOpen]);

  return (
    <>
      <Box
        {...{ ref: criticalEventsContainerRef }}
        display="flex"
        gridGap="0.5rem"
        alignItems="center">
        <IconButton
          id="critical-events-menu"
          title="Open critical events"
          onClick={() => setIsCriticalEventsOpen(isOpen => !isOpen)}>
          <Badge badgeContent={totalEvents} color="error">
            {children}
          </Badge>
        </IconButton>
      </Box>

      <Popper
        open={isCriticalEventsOpen}
        anchorEl={criticalEventsContainerRef?.current}
        placement="bottom-end"
        transition
        disablePortal
        className={classes.popper}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <ClickAwayListener onClickAway={() => setIsCriticalEventsOpen(false)}>
              <Paper className={classes.content}>
                <Box display="flex" justifyContent="space-between" className={classes.dialogTitle}>
                  <Typography variant="h5">Critical Events</Typography>

                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => setIsCriticalEventsOpen(false)}>
                    <Close />
                  </IconButton>
                </Box>

                <Box sx={{ borderBottom: 2, borderColor: 'silver' }}>
                  <Tabs
                    disableQueryStringState
                    tabs={[
                      {
                        label: `Incidents (${totalIncidents})`,
                        component: (
                          <>
                            <Box
                              key={criticalEventsListKeyIncidents}
                              display="flex"
                              flexDirection="column"
                              gridGap="1rem">
                              {useIncidentStateOnCriticalEvents
                                ? dataIncidentsState?.incidentsState?.data.map(incident => (
                                    <Link
                                      key={incident.id}
                                      component={RouterLink}
                                      className={classes.criticalEventLink}
                                      to={`/incidents/${incident.id}`}
                                      title="View incident">
                                      <>
                                        <Box display="flex" gridGap="0.5rem" flexDirection="column">
                                          <Box display="flex" alignItems="center" gridGap="0.5rem">
                                            <StatusChip
                                              type="Event"
                                              status={incident.status as StatusEnum}
                                              isAlertStatus
                                            />
                                            <StatusChip
                                              type="Severity"
                                              severityStatus={
                                                incident.severity as SeverityStatusEnum
                                              }
                                              isAlertStatus={false}
                                            />{' '}
                                            <Typography className="criticalEventTitle" variant="h6">
                                              {reduceString(incident.title, 80)}
                                            </Typography>
                                          </Box>
                                          <Typography variant="caption">
                                            {dayjs(incident.failureHappenedAt).fromNow()}
                                          </Typography>
                                        </Box>
                                        <Divider className={classes.divider} />
                                      </>
                                    </Link>
                                  ))
                                : dataIncidents?.incidents?.map(incident => (
                                    <Link
                                      key={incident.incidentId}
                                      component={RouterLink}
                                      className={classes.criticalEventLink}
                                      to={`/incidents/${incident.incidentId}`}
                                      title="View incident">
                                      <>
                                        <Box display="flex" gridGap="0.5rem" flexDirection="column">
                                          <Box display="flex" alignItems="center" gridGap="0.5rem">
                                            <StatusChip
                                              type="Event"
                                              status={incident.status as StatusEnum}
                                              isAlertStatus
                                            />
                                            <StatusChip
                                              type="Severity"
                                              severityStatus={
                                                incident.severity as SeverityStatusEnum
                                              }
                                              isAlertStatus={false}
                                            />{' '}
                                            <Typography className="criticalEventTitle" variant="h6">
                                              {reduceString(incident.title, 80)}
                                            </Typography>
                                          </Box>
                                          <Typography variant="caption">
                                            {dayjs(incident.happenedAt).fromNow()}
                                          </Typography>
                                        </Box>
                                        <Divider className={classes.divider} />
                                      </>
                                    </Link>
                                  ))}
                            </Box>
                            {hasDataIncidents && (
                              <Box className={classes.buttonsContainer} mt={2}>
                                <Pagination
                                  page={pageIncidents}
                                  count={Math.ceil(totalIncidents / QUERY_SIZE)}
                                  defaultPage={pageIncidents}
                                  size="large"
                                  color="primary"
                                  onChange={(_, value) => setPageIncidents(value)}
                                />
                              </Box>
                            )}

                            {!hasDataIncidents && (
                              <Typography variant="h6">No critical events</Typography>
                            )}
                          </>
                        )
                      },
                      {
                        label: `Alerts (${totalAlerts})`,
                        component: (
                          <>
                            <Box
                              key={criticalEventsListKeyAlerts}
                              display="flex"
                              flexDirection="column"
                              gridGap="1rem">
                              {dataAlerts?.alertsState?.data?.map(alert => (
                                <Link
                                  key={alert.id}
                                  component={RouterLink}
                                  className={classes.criticalEventLink}
                                  to={`/alert-center?ids=${alert.id}`}
                                  title="View Alert">
                                  <>
                                    <Box display="flex" gridGap="0.5rem" flexDirection="column">
                                      <Box display="flex" alignItems="center" gridGap="0.5rem">
                                        <StatusChip
                                          type="Event"
                                          status={alert.status as StatusEnum}
                                          isAlertStatus
                                        />
                                        <StatusChip
                                          type="Severity"
                                          severityStatus={alert.severity as SeverityStatusEnum}
                                          isAlertStatus={false}
                                        />{' '}
                                        <Typography className="criticalEventTitle" variant="h6">
                                          {reduceString(alert.title, 80)}
                                        </Typography>
                                      </Box>
                                      <Typography variant="caption">
                                        {dayjs(alert.alertHappenedAt).fromNow()}
                                      </Typography>
                                    </Box>
                                    <Divider className={classes.divider} />
                                  </>
                                </Link>
                              ))}
                            </Box>
                            {hasDataAlerts && (
                              <Box className={classes.buttonsContainer} mt={2}>
                                <Pagination
                                  page={pageAlerts}
                                  count={Math.ceil(totalAlerts / QUERY_SIZE)}
                                  defaultPage={pageAlerts}
                                  size="large"
                                  color="primary"
                                  onChange={(_, value) => setPageAlerts(value)}
                                />
                              </Box>
                            )}

                            {!hasDataAlerts && (
                              <Typography variant="h6">No critical events</Typography>
                            )}
                          </>
                        )
                      }
                    ]}
                  />
                </Box>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}
