import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertMongoDbAtlasGuide = ({
  orgUid,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'MongoDB Atlas',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/alerts/v1/mongodb-atlas/${integrationUid}?token=${token}`,
    instructions: {
      isNumeric: false,
      steps: [
        `You can enter the severity via queryString, the field must be filled in with the following values:`,
        '• critical',
        '• high',
        '• moderate',
        '• low',
        '• informational',
        'If you do not want to classify the alert created, just do not send the queryString.'
      ]
    },
    snippetWarning: {
      message: 'Need help or more information? Check out our ',
      linkHref:
        'https://docs.elven.works/guia-configuracao-do-external-service-alert-mongodb-atlas-na-elven-platform/',
      linkText: 'documentation.'
    }
  };
};
