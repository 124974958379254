import { Box, Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  }
}));

const DeleteDialog = ({ handleClose, open, handleDelete, isLoadingDelete, values }) => {
  const classes = useStyles();
  return (
    <OldDeleteDialog open={open} onClose={handleClose} title="Delete Token" minWidth="md" fullWidth>
      <Box paddingBottom={3}>
        <Typography color="textPrimary">Do you really want to delete this token?</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <ButtonSimple
          color="primary"
          variant="contained"
          onClick={() => {
            handleDelete({
              id: values.id,
              tokenType: values.tokenType,
              tokenUid: values?.tokenUid,
              tokenName: values.name
            });
          }}
          text="Confirm"
        />

        <Box pl={2}>
          <ButtonSimple onClick={handleClose} variant="outlined" text="Cancel" color="primary" />
        </Box>
        <Backdrop open={isLoadingDelete} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>
      </Box>
    </OldDeleteDialog>
  );
};

export default DeleteDialog;
