import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Box,
  DialogTitle as MuiDialogTitle,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import ButtonSimple from 'componentsV4/Button';
import RadioGroup from 'componentsV4/RadioGroup';
import { TextButton } from 'componentsV4/TextButton';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { withThemeV4 } from 'theme/v4';

import useStyles from './styles';

const DeleteSchedules = `#graphql
  mutation(
    $scheduleUid: String!
    $scheduleId: Float!
    $deleteOption: String!
  ) {
    deleteSchedules(
      scheduleUid: $scheduleUid
      scheduleId: $scheduleId
      deleteOption: $deleteOption
    )
  }
`;

const ModalDeleteSchedule = ({
  open,
  handleClose,
  scheduleId,
  scheduleUid,
  groupName
}: {
  open: boolean;
  handleClose: () => void;
  scheduleId: number;
  scheduleUid: string;
  groupName: string;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const queryCache = useQueryCache();

  const [{ fetching, error }, deleteSchedules] = useMutation<
    boolean,
    { scheduleUid: string; scheduleId: number; deleteOption: string }
  >(DeleteSchedules);

  const serverErrorMessage =
    error?.graphQLErrors && error?.graphQLErrors[0] && error.graphQLErrors[0].originalError
      ? error.graphQLErrors[0].originalError.message
      : null;

  const onDeleteSchedules = () => {
    deleteSchedules(
      { scheduleUid, scheduleId, deleteOption },
      injectAuditTrailMetadata({ groupName: groupName, scheduleUid: scheduleUid })
    ).then(({ error }) => {
      if (error) return;

      handleClose();
      queryCache.invalidateQueries('callRotationSchedules');
    });
  };

  const { control, watch } = useForm({
    defaultValues: {
      delete: 'this'
    }
  });

  const deleteOption = watch('delete');

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ classes: { root: classes.paper } }}>
      <MuiDialogTitle
        disableTypography
        style={{
          paddingBottom: theme.spacing(5),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4)
        }}>
        <Box mt={3}>
          <Typography variant="h3">Delete Schedules</Typography>
        </Box>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <Box mb={5}>
        <DialogContent
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4)
          }}>
          <Box display="flex" pl={1}>
            <RadioGroup
              defaultValue="this"
              control={control}
              options={[
                { label: 'This schedule', value: 'this' },
                { label: 'This and following schedules', value: 'next' }
              ]}
              name="delete"
            />
          </Box>

          <Box display="flex" mt={5}>
            <ButtonSimple
              variant="contained"
              text={'Delete'}
              color={'primary'}
              disabled={false}
              onClick={onDeleteSchedules}
              style={{
                width: 'initial',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                marginRight: theme.spacing(3)
              }}
            />
            <TextButton
              text={'Cancel'}
              color={'primary'}
              disabled={false}
              onClick={handleClose}
              style={{ width: 'initial' }}
            />
          </Box>

          {fetching && <LoadingOverlay />}

          {serverErrorMessage && (
            <Box mt={3}>
              <Typography variant="subtitle2" color="error">
                {serverErrorMessage}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default withThemeV4(ModalDeleteSchedule);
