import React from 'react';

import DayJsUtils from '@date-io/dayjs';
import { Box, Typography, Divider, Icon } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab-old';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { ButtonDefault } from 'oldComponents/Button';
import Dialog from 'oldComponents/Dialog';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

import useScheduledEvent from 'hooks/queriesGraphQL/useScheduledEvent';
import { ScheduledEvent, Frequency } from 'hooks/queriesGraphQL/useScheduledEvent';

import { checkDay } from './checkDay';
import { CustomAvatar } from './CustomAvatar';
import { defaultValues } from './defaultValues';
import { FrequencyDay } from './FrequencyDay';
import useStyles from './styles';

export type ScheduledEventProps = {
  serviceId: number;
  applicationId: number;
  applicationName: string;
  uid: string;
  entity: string;
  openerComponent: any;
  setActivated: (enabled: boolean) => void;
};

export const ScheduledEventDialog = ({
  serviceId,
  applicationId,
  applicationName,
  uid,
  entity,
  openerComponent: OpenerComponent,
  setActivated
}: ScheduledEventProps) => {
  const classes = useStyles();

  const { productId } = useParams<{ productId: string }>();

  const { scheduledEvent, onUpdate, onCreate, reexecuteQuery } = useScheduledEvent({
    serviceId,
    applicationId,
    applicationName,
    uid,
    type: 'operation',
    productId: Number(productId)
  });

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      frequencies: defaultValues
    }
  });

  const { fields } = useFieldArray({
    keyName: 'frequency_id',
    control,
    name: 'frequencies'
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!scheduledEvent?.frequencies) return;

    setValue('frequencies', scheduledEvent.frequencies);
  }, [scheduledEvent, setValue]);

  const formValues = useWatch({
    control: control,
    name: 'frequencies'
  }) as [Frequency];

  React.useEffect(() => {
    const values = Object.create(formValues);

    let isEnabled = false;

    values.forEach((frequency: Frequency) => {
      if (frequency.enabled) {
        isEnabled = true;
      }
    });

    setActivated(isEnabled);
  }, [formValues, setActivated]);

  const changeEnabled = (index: number, enabled: boolean) => {
    setValue(`frequencies.${index}.enabled`, !enabled);
  };

  const date = new Date(new Date().toDateString());

  const [startDefaultTime, setStartDefaultTime] = React.useState(date);
  const [finishDefaultTime, setFinishDefaultTime] = React.useState(date);

  const applyDefaultTime = () => {
    const oldValues = Object.create(formValues);

    let hasEnabled = false;

    const newValue: [Frequency] = oldValues.map((value: Frequency) => {
      if (value.enabled) {
        hasEnabled = true;

        return {
          ...value,
          start: startDefaultTime,
          finish: finishDefaultTime
        };
      }

      return {
        ...value
      };
    });

    setValue('frequencies', newValue, { shouldDirty: hasEnabled ? true : false });
  };

  const onSubmit = (data: any) => {
    const values = Object.create(formValues);

    const payload: ScheduledEvent = {
      id: Number(values[0].scheduledEventId),
      applicationId,
      uid,
      entity,
      serviceId,
      type: 'operation',
      recurrent: true,
      begin: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
      end: null,
      frequencies: []
    };

    if (!payload.id) delete payload.id;

    payload.frequencies = data.frequencies.map((frequency: Frequency) => {
      const start = new Date(new Date().toDateString());

      start.setMinutes(new Date(frequency.start).getMinutes());
      start.setHours(new Date(frequency.start).getHours());

      const frequencyStart = dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ');

      const finish = new Date(new Date().toDateString());

      finish.setMinutes(new Date(frequency.finish).getMinutes());
      finish.setHours(new Date(frequency.finish).getHours());

      const frequencyFinish = dayjs(finish).format('YYYY-MM-DDTHH:mm:ssZ');

      if (!Number(frequency.id) && !Number(frequency.scheduledEventId)) {
        delete frequency.id;
        delete frequency.scheduledEventId;

        return {
          ...frequency,
          start: frequencyStart,
          finish: frequencyFinish,
          dayOfWeek: Number(frequency.dayOfWeek)
        };
      }

      return {
        ...frequency,
        id: Number(frequency.id),
        scheduledEventId: Number(frequency.scheduledEventId),
        start: frequencyStart,
        finish: frequencyFinish,
        dayOfWeek: Number(frequency.dayOfWeek)
      };
    });

    if (payload.id) {
      return onUpdate(payload, handleClose);
    }

    return onCreate(payload, handleClose);
  };

  return (
    <>
      <OpenerComponent
        onClick={() => {
          setOpen(true);
          reexecuteQuery();
        }}
      />
      <Dialog
        fullWidth
        PaperProps={{ classes: { root: classes.paper } }}
        open={open}
        onClose={handleClose}
        showCloseButton={true}
        onPrimaryAction={handleSubmit(data => onSubmit(data))}
        primaryActionLabel="Save opening hours"
        showSecondaryAction={true}
        secondaryActionLabel="Cancel"
        customDialogTitle={classes.customDialogTitle}
        customButton={isDirty ? classes.customButton : classes.customButtonDisabled}
        title="Application opening hours"
        buttonDisabled={isDirty ? false : true}>
        <Box className={classes.dialogContent}>
          <Typography className={classes.subtitle}>
            By setting at least one date, the default (24h/7d) will change to the times defined by
            you
          </Typography>
          <Box display="flex" flexGrow={1}>
            <Box flexGrow={1}>
              <Typography className={classes.inputlabel}>Days of the week</Typography>
              <Box display="flex" pt={1} alignItems="center">
                <AvatarGroup>
                  {fields.map((field, index) => {
                    return (
                      <CustomAvatar
                        key={field.frequency_id}
                        index={index}
                        watch={watch}
                        changeEnabled={changeEnabled}
                        checkDay={checkDay}
                      />
                    );
                  })}
                </AvatarGroup>
              </Box>
            </Box>

            <Box>
              <Typography className={classes.inputlabel}>Opening hours for all days</Typography>
              <Box display="flex">
                <Box display="flex" alignItems="center">
                  <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <KeyboardTimePicker
                      className={classes.timePicker}
                      format="HH:mm"
                      inputVariant="outlined"
                      value={startDefaultTime}
                      ampm={false}
                      onChange={(date, value) => {
                        if (value?.length) {
                          const start = new Date();

                          start.setHours(Number(value.slice(0, 2)));
                          start.setMinutes(Number(value.slice(3, 5)));

                          setStartDefaultTime(start);
                        }
                      }}
                      InputProps={{
                        classes: { input: classes.inputTimePicker }
                      }}
                      InputAdornmentProps={{
                        classes: { root: classes.inputAdornment }
                      }}
                      error={false}
                      helperText=""
                    />
                  </MuiPickersUtilsProvider>
                  <Typography variant="h5" className={classes.middleTextDefaultValue}>
                    to
                  </Typography>
                  <MuiPickersUtilsProvider utils={DayJsUtils}>
                    <KeyboardTimePicker
                      className={classes.timePicker}
                      format="HH:mm"
                      inputVariant="outlined"
                      value={finishDefaultTime}
                      ampm={false}
                      onChange={(date, value) => {
                        if (value?.length) {
                          const finish = new Date();

                          finish.setHours(Number(value.slice(0, 2)));
                          finish.setMinutes(Number(value.slice(3, 5)));

                          setFinishDefaultTime(finish);
                        }
                      }}
                      InputProps={{
                        classes: { input: classes.inputTimePicker }
                      }}
                      InputAdornmentProps={{
                        classes: { root: classes.inputAdornment }
                      }}
                      error={false}
                      helperText=""
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <ButtonDefault
                  className={classes.saveHourButton}
                  startIcon={<Icon style={{ fontSize: '24px' }}>content_copy</Icon>}
                  size="medium"
                  variant="text"
                  onClick={applyDefaultTime}>
                  <p className={classes.copyButtonText}>Copy to everyday</p>
                </ButtonDefault>
              </Box>
            </Box>
          </Box>

          <Divider className={classes.divider} />

          {fields.map((field, index) => {
            return (
              <FrequencyDay
                key={field.frequency_id}
                field={field}
                index={index}
                register={register}
                checkDay={checkDay}
                watch={watch}
                changeEnabled={changeEnabled}
                control={control}
              />
            );
          })}
        </Box>
      </Dialog>
    </>
  );
};
