import { Box, CardContent, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { SeverityStatusEnum } from 'types/external/Severity';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useGetIncidentAlerts } from 'hooks/queriesGraphQL/useGetIncidentAlerts';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import { useStyles } from './styles';

type IncidentAlertsProps = {
  incidentId: number;
};

function IncidentAlerts({ incidentId }: IncidentAlertsProps) {
  const classes = useStyles();
  const theme = useTheme();

  const { data: incidentAlerts, isLoading } = useGetIncidentAlerts(incidentId);

  const hasData = incidentAlerts.length > 0;

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} variant="subtitle2">
          Alerts
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />

        <Box
          className={clsx(classes.scrollBar, !hasData && classes.noData)}
          position="relative"
          gridGap={theme.spacing()}>
          {incidentAlerts.map(alert => (
            <Box display="flex" alignItems="center" mt={1}>
              <StatusChip
                type="Severity"
                severityStatus={alert.severity as SeverityStatusEnum}
                isAlertStatus={false}
              />
              <Typography variant="body1" style={{ lineHeight: 'inherit' }}>
                {alert.title}
              </Typography>
            </Box>
          ))}
          {!hasData && !isLoading && (
            <Typography color="textPrimary" variant="h6">
              No Alerts
            </Typography>
          )}

          {isLoading && <LoadingOverlay size={theme.spacing(4)} />}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default IncidentAlerts;
