import { useState } from 'react';

import cloudStatus from 'constants/cloudStatus';

import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationURQL } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';

import Toolbar from '../../../oldComponents/Crud/ToolbarQueryProps';
import DataGrid from '../components/Crud/DataGrid';

import columns from './columns';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  }
});

const DeleteCloudMutation = `#graphql
  mutation(
    $cloudId: Float!
  ) {
    deleteCloud(cloudId: $cloudId) {
      message
      success
    }
  }
`;

const CloudsList = ({ classes, permissions }) => {
  const dispatch = useDispatch();

  const shouldUseGraphqlOnClouds = useFeatureFlagUnleash('cloudsGraphql', { queryString: true });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState('name,ASC');
  const [query, setQuery] = useState('');

  const handleSort = ({ selector }, sortDirection) => {
    setSort(`${selector},${sortDirection.toUpperCase()}`);
  };

  const queryKey = ['clouds', page, rowsPerPage, sort, query];

  const queryCache = useQueryCache();
  const [removeErrorCloudId, setOnRemoveErrorCloudId] = useState(null);

  const [handleRemove, { isLoading: isLoadingDelete }] = useMutation(
    async ({ id }) => axios.delete(`/clouds/${id}`),
    {
      onSuccess: data => {
        queryCache.invalidateQueries(queryKey);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Cloud removed successfully'
        });
        return data;
      },
      onError: (err, { id }) => {
        setOnRemoveErrorCloudId(id);
      }
    }
  );

  const [{ fetching: isLoadingDeleteURQL }, deleteCloudMutation] = useMutationURQL(
    DeleteCloudMutation
  );

  const deleteLoading = isLoadingDelete || isLoadingDeleteURQL;

  const removeCloud = data => {
    if (!shouldUseGraphqlOnClouds) {
      return handleRemove(data);
    }
    deleteCloudMutation(
      {
        cloudId: data?.id
      },
      injectAuditTrailMetadata({ cloudName: data.name })
    ).then(result => {
      if (result.error || !result.data) return setOnRemoveErrorCloudId(data?.id);

      queryCache.invalidateQueries(queryKey);

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Cloud removed successfully'
      });

      return data;
    });
  };

  const { data, isSuccess, isError, isLoading } = useQuery(
    queryKey,
    () =>
      axios
        .get(`/clouds`, {
          params: {
            page,
            per_page: rowsPerPage,
            sort,
            q: query || undefined
          }
        })
        .then(response => response.data),
    {
      refetchInterval: 1000 * 5, // 5 seconds
      // Will be temporarily disabled if there's a error being shown when trying to delete cloud
      // so it doesn't cause a rerender
      enabled: !removeErrorCloudId
    }
  );

  const [handleValidate] = useMutation(id => axios.post(`/clouds/${id}/validate`), {
    onMutate: id => {
      // Set pending state for the cloud while the request doesn't end
      queryCache.setQueryData(queryKey, old => {
        const { data, total } = old;

        const newData = data.map(item => {
          if (item.id === id) {
            return { ...item, status: cloudStatus.Pending };
          }
          return item;
        });

        return { data: newData, total };
      });
    },
    onSettled: () => {
      // Refetch clouds list
      queryCache.invalidateQueries(queryKey);
    },
    onSuccess: () => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Validating cloud credentials'
      });
    },
    onError: err => {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: err,
        ga: { category: 'ERROR' }
      });
    }
  });

  return (
    <>
      <Breadcrumb items={[{ label: 'Monitoring' }, { label: 'Cloud Center' }]} />

      <Toolbar
        title="Clouds"
        buttonNewName="New"
        entity="clouds"
        searchPlaceholder="Search clouds"
        disableNew={!permissions.new}
        query={query}
        setQuery={setQuery}
      />

      <div className={classes.content}>
        {isLoading && <CircularProgress style={{ display: 'block', margin: '0 auto' }} />}

        {isError && "Couldn't load clouds :("}

        {isSuccess && data && (
          <DataGrid
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            paginationDefaultPage={page}
            onSort={handleSort}
            onRemove={removeCloud}
            clouds={data}
            entity="clouds"
            cols={columns}
            permissions={permissions}
            onCheckCloud={handleValidate}
            isLoadingDelete={deleteLoading}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(CloudsList);
