import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Link
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { format, parseISO } from 'date-fns';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import Dialog from 'oldComponents/Dialog';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { OriginEnum } from 'types/external/Origin';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Title } from 'components/Title';
import { EventsFilters } from 'componentsV4/Filters';
import { LoadingOverlay } from 'componentsV4/Loading';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useQueryParams from 'helpers/useQueryParams';
import { useAlerts } from 'hooks/queriesGraphQL/useAlerts';
import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { days } from 'views/IncidentCenter';
import {
  transformAlertPeriodQuery,
  transformSeverityQuery,
  transformStatusQuery
} from 'views/IncidentCenter/transformQuery';

import { StatusChip } from './components/StatusChip';
import { useStyles } from './styles';

const TYPING_DEBOUNCE_TIME = 500; // 0.5s
const PER_PAGE = 10;

const DeleteAlertMutation = `#graphql
  mutation ($alertUid: String!){
      deleteAlert(alertUid: $alertUid)
  }
`;

const AcknowledgeAlertMutation = `#graphql
  mutation ($alertUid: String!){
    acknowledgeAlert(alertUid: $alertUid){
      success
      message
    }
  }
`;

const ResolveAlertMutation = `#graphql
  mutation ($alertUid: String!){
    resolveAlert(alertUid: $alertUid){
      success
      message
    }
  }
`;

export function AlertCenter() {
  const queryParams = useQueryParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const shouldUseNewAlertList = useFeatureFlagUnleash('newAlertList', {
    queryString: true
  });

  const [search, setSearch] = useState('');

  const [alertStatus, setAlertStatus] = useState<StatusEnum | string>('All');
  const [alertSeverity, setAlertSeverity] = useState<SeverityStatusEnum | string>('All');
  const [alertPeriod, setAlertPeriod] = useState<'All' | string>('All');
  const [alertUid, setAlertUid] = useState<string>('');
  const [uid, setUid] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [betweenValues, setBetweenValues] = useState<string>('now-1y,now');
  const [origins, setOrigins] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);

  const [page, setPage] = useState(1);

  const statusURL = queryParams.get('status');
  const severityURL = queryParams.get('severity');
  const periodURL = queryParams.get('period');
  const uidURL = queryParams.get('uid');
  const acknowledgeURL = queryParams.get('acknowledge');
  const resolveURL = queryParams.get('resolve');
  const originsURL = queryParams.get('origins');
  const idsURL = queryParams.get('ids');

  React.useEffect(() => {
    statusURL && transformStatusQuery(statusURL, setAlertStatus);
    severityURL && transformSeverityQuery(severityURL, setAlertSeverity);
    originsURL && setOrigins(originsURL.split(','));
    idsURL && setIds(idsURL.split(','));

    if (periodURL?.length) {
      days.includes(periodURL)
        ? setBetweenValues(`now-${periodURL},now`)
        : setBetweenValues(periodURL ?? 'now-1y,now');

      return;
    }

    periodURL && transformAlertPeriodQuery(periodURL, setAlertPeriod);
    uidURL && setAlertUid(uidURL);
  }, [
    statusURL,
    severityURL,
    periodURL,
    uidURL,
    acknowledgeURL,
    resolveURL,
    alertPeriod,
    alertSeverity,
    alertStatus,
    alertUid,
    originsURL,
    idsURL,
    setOrigins,
    setIds
  ]);

  const enableResolveAlert = useFeatureFlagUnleash('useResolveAlert', {
    queryString: true
  });

  const hasPermissionAcknowledge = usePermission('AlertController-post-/alert/:uid/acknowledge');
  const hasPermissionDelete = usePermission('AlertController-delete-/alert/:uid');
  const hasPermissionResolve = usePermission('AlertController-post-/alert/:uid/resolve');

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const [statusAlert, setStatus] = useState<StatusEnum>();
  const [originAlert, setOrigin] = useState<OriginEnum>();

  const handleToggleMenu = (
    event: React.MouseEvent<HTMLElement>,
    alertUid: string,
    status: StatusEnum,
    origin: OriginEnum,
    alertTitle: string
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setUid(alertUid);
    setStatus(status);
    setOrigin(origin);
    setTitle(alertTitle);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAcknowledgeDialog, setOpenAcknowledgeDialog] = React.useState(false);
  const [openResolveDialog, setOpenResolveDialog] = React.useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
    handleCloseMenu();
  };

  const handleAcknowledgeDialog = () => {
    setOpenAcknowledgeDialog(!openAcknowledgeDialog);
    handleCloseMenu();
  };

  const handleResolveDialog = () => {
    setOpenResolveDialog(!openResolveDialog);
    handleCloseMenu();
  };

  const [, deleteAlertMutation] = useMutation(DeleteAlertMutation);

  const [acknowledgeAlertMutationResult, acknowledgeAlertMutation] = useMutation<
    { acknowledgeAlert: { success: boolean; message: string } },
    { alertUid: typeof alertUid }
  >(AcknowledgeAlertMutation);

  const [resolveAlertMutationResult, resolveAlertMutation] = useMutation<
    { resolveAlert: { success: boolean; message: string } },
    { alertUid: typeof alertUid }
  >(ResolveAlertMutation);

  const handleClearFilters = () => {
    removeQueryParams('status');
    removeQueryParams('severity');
    removeQueryParams('origins');
    removeQueryParams('ids');
    removeQueryParams('uid');
    removeQueryParams('acknowledge');
    removeQueryParams('resolve');

    setAlertUid('');
    setAlertStatus('All');
    setAlertSeverity('All');
    setOrigins([]);
    setIds([]);

    setPage(1);
    reexecuteAlertQuery();
  };

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const { data: dataAlerts } = useAlertsState({});

  const idsOptions = dataAlerts?.alertsState?.data.map(({ id }) => ({
    value: String(id),
    label: String(id)
  }));

  const {
    data: alertList,
    fetching: isFetchingAlertList,
    reexecuteQuery: reexecuteQueryAlertList
  } = useAlertsState({
    from: page,
    size: PER_PAGE,
    query: customElasticQuery({
      customRegex: '\\*',
      queryFilters: [
        {
          key: 'uid',
          value: alertUid,
          isRegex: false
        },
        {
          key: 'title',
          value: search,
          isRegex: true
        },
        {
          key: 'cause',
          value: search,
          isRegex: true
        },
        {
          key: 'id',
          value: ids,
          isRegex: false
        },
        {
          key: 'status',
          value: alertStatus === 'All' ? 'all' : alertStatus,
          isRegex: false
        },
        {
          key: 'severity',
          value: alertSeverity === 'All' ? 'all' : alertSeverity,
          isRegex: false
        },
        {
          key: 'origins.entity_uid',
          value: origins,
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'alerts',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    betweenKey: 'alert_happened_at',
    betweenValues,
    pause: !shouldUseNewAlertList
  });

  const {
    data: { alerts, total },
    fetching: isFetchingAlerts,
    reexecuteQuery: reexecuteNewAlertsQuery
  } = useAlerts({
    page: page,
    perPage: 10,
    query: customElasticQuery({
      customRegex: '\\*',
      queryFilters: [
        {
          key: 'uid',
          value: alertUid,
          isRegex: false
        },
        {
          key: 'title',
          value: search,
          isRegex: true
        },
        {
          key: 'cause',
          value: search,
          isRegex: true
        },
        {
          key: 'id',
          value: ids,
          isRegex: false
        },
        {
          key: 'status',
          value: alertStatus === 'All' ? 'all' : alertStatus,
          isRegex: false
        },
        {
          key: 'severity',
          value: alertSeverity === 'All' ? 'all' : alertSeverity,
          isRegex: false
        },
        {
          key: 'origins.entity_uid',
          value: origins,
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'alerts',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    betweenKey: 'alert_happened_at',
    betweenValues,
    pause: shouldUseNewAlertList
  });

  const alertData = (!shouldUseNewAlertList ? alerts : alertList?.alertsState?.data) || [];
  const alertTotal = (!shouldUseNewAlertList ? total : alertList?.alertsState?.total) || 0;
  const isFetching = !shouldUseNewAlertList ? isFetchingAlerts : isFetchingAlertList;
  const reexecuteAlertQuery = !shouldUseNewAlertList
    ? reexecuteNewAlertsQuery
    : reexecuteQueryAlertList;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const deleteAlert = () => {
    deleteAlertMutation(
      { alertUid: uid },
      injectAuditTrailMetadata({ title: title, alertUid: uid })
    ).then(() => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Alert deleted successfully'
      });

      setTimeout(() => reexecuteAlertQuery(), 2000);
    });

    setOpenDeleteDialog(false);
  };

  const acknowledgeAlert = () => {
    acknowledgeAlertMutation(
      { alertUid: uid },
      injectAuditTrailMetadata({ title: title, alertUid: uid })
    )
      .then(response => {
        if (response.data?.acknowledgeAlert?.success) {
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Acknowledged Successful'
          });
          setOpenAcknowledgeDialog(false);
          setTimeout(() => reexecuteAlertQuery(), 2000);
        } else {
          const errorMessage =
            response.data?.acknowledgeAlert?.message || "Couldn't acknowledge alert :(";

          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: errorMessage }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: "Couldn't acknowledge alert :" + error.message }
        });
      });
  };

  const resolveAlert = () => {
    resolveAlertMutation(
      { alertUid: uid },
      injectAuditTrailMetadata({ title: title, alertUid: uid })
    )
      .then(response => {
        if (response.data?.resolveAlert?.success) {
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Resolved Successful'
          });
          setOpenResolveDialog(false);
          setTimeout(() => reexecuteAlertQuery(), 2000);
        } else {
          const errorMessage = response.data?.resolveAlert?.message || "Couldn't resolve alert :(";

          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: errorMessage }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: "Couldn't resolve alert :" + error.message }
        });
      });
  };

  useEffect(() => {
    if (alertUid) {
      if (acknowledgeURL) {
        setUid(alertUid);
        setOpenAcknowledgeDialog(acknowledgeURL === 'true' ? true : false);
      }

      if (resolveURL) {
        setUid(alertUid);
        setOpenResolveDialog(resolveURL === 'true' ? true : false);
      }
    }

    if (
      !shouldUseNewAlertList &&
      alertUid &&
      !isFetching &&
      alertList?.alertsState.data.length === 1 &&
      alertList?.alertsState.data[0].status === StatusEnum.Alarmed
    ) {
      if (acknowledgeURL && hasPermissionAcknowledge) {
        setUid(alertUid);
        setOpenAcknowledgeDialog(acknowledgeURL === 'true' ? true : false);
      }

      if (resolveURL && hasPermissionResolve) {
        setUid(alertUid);
        setOpenResolveDialog(resolveURL === 'true' ? true : false);
      }
    }
  }, [
    shouldUseNewAlertList,
    isFetching,
    alertList?.alertsState.data.length,
    acknowledgeURL,
    resolveURL,
    alertList?.alertsState.data,
    uid,
    alertUid,
    hasPermissionAcknowledge,
    hasPermissionResolve
  ]);

  const handleApplyFilters = (filters: any) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    filters.severity === 'all'
      ? removeQueryParams('severity')
      : addQueryParams('severity', filters.severity);

    const originUids = filters?.origins?.map((origin: any) => origin.value);

    !filters.origins?.length
      ? removeQueryParams('origins')
      : addQueryParams('origins', originUids?.toString() || '');

    if (filters?.origins) {
      setOrigins(filters.origins.map((origin: { value: string }) => origin.value));
    }

    const originIds = filters?.ids?.map((id: any) => id.value);

    !filters.ids?.length
      ? removeQueryParams('ids')
      : addQueryParams('ids', originIds?.toString() || '');

    if (filters?.ids) {
      setIds(filters.ids.map((id: { value: string }) => id.value));
    }

    setAlertStatus(filters.status as StatusEnum);
    setAlertSeverity(filters.severity);

    setPage(1);
    reexecuteAlertQuery();
  };

  const handleApplyPeriod = ({ period }: { period: string }) => {
    if (period === 'all') {
      removeQueryParams('period');
      setBetweenValues(`now-1y,now`);
      setPage(1);
      return;
    }

    addQueryParams('period', period);
    setBetweenValues(`now-${period},now`);
    setPage(1);
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    addQueryParams('period', betweenValue);
    setBetweenValues(betweenValue);
    setPage(1);
  };

  const handleClearCustomRange = () => {
    removeQueryParams('period');
    setBetweenValues('now-1y,now');
    setPage(1);
  };

  const appliedFilters = () => {
    return {
      status: alertStatus === 'All' ? 'all' : alertStatus,
      severity: alertSeverity === 'All' ? 'all' : alertSeverity,
      betweenValues: periodURL ? betweenValues : '',
      origins,
      ids
    };
  };

  const statusOptions = {
    label: 'Status',
    options: [
      { value: 'all', label: 'All' },
      { value: StatusEnum.Alarmed, label: 'Alarmed' },
      { value: StatusEnum.Resolved, label: 'Resolved' },
      { value: StatusEnum.Acknowledged, label: 'Acknowledged' }
    ]
  };

  const disableResolve = !hasPermissionResolve || statusAlert === StatusEnum.Resolved;
  const disableAcknowledge = !hasPermissionAcknowledge || statusAlert !== StatusEnum.Alarmed;
  const disableDelete = !hasPermissionDelete || originAlert !== OriginEnum.API;

  return (
    <>
      <Breadcrumb items={[{ label: 'Incident Management' }, { label: 'Alert Center' }]} />

      <Title title="Alert Center"></Title>

      <EventsFilters
        handleChangeSearch={handleChangeSearch}
        onApplyFilters={handleApplyFilters}
        onApplyPeriod={handleApplyPeriod}
        onApplyCustomRange={handleApplyCustomRange}
        onClearFilters={handleClearFilters}
        onClearCustomRange={handleClearCustomRange}
        appliedFilters={appliedFilters}
        hideSeverity={false}
        hideOrigins={false}
        statusOptions={statusOptions}
        searchPlaceholder="Search by title or cause"
        originsQuery={'(type: integration) OR (type: resource)'}
        idsOptions={idsOptions}
        hideId={false}
      />

      <Box>
        {isFetching && (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={90} color="primary" thickness={3} />
          </Box>
        )}

        {!isFetching && (
          <>
            {!alertData?.length ? (
              <Box display="flex" justifyContent="center">
                <Typography variant="body2" color="textSecondary">
                  No results found
                </Typography>
              </Box>
            ) : (
              alertData?.map(alert => {
                return (
                  <Box key={alert.id} mt={3}>
                    <Divider className={classes.alertDivider} />
                    <Box display="flex" alignItems="center">
                      <StatusChip
                        type="Severity"
                        severityStatus={alert.severity}
                        isAlertStatus={false}
                      />
                      <StatusChip type="Event" status={alert.status} isAlertStatus={true} />
                      <Typography variant="h6" className={classes.alertTitle}>
                        {alert.title}
                      </Typography>

                      <Button
                        className={classes.menuButton}
                        variant="outlined"
                        onClick={e =>
                          handleToggleMenu(e, alert.uid, alert.status, alert.origin, alert.title)
                        }>
                        <MoreVert />
                      </Button>

                      <Menu
                        open={isMenuOpen}
                        elevation={1}
                        anchorEl={menuAnchorEl}
                        getContentAnchorEl={null}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        classes={{ paper: classes.paperProps }}>
                        <MenuItem
                          disabled={disableAcknowledge}
                          className={classes.menuItem}
                          onClick={handleAcknowledgeDialog}>
                          Acknowledge Alert
                        </MenuItem>
                        {enableResolveAlert && (
                          <MenuItem
                            disabled={disableResolve}
                            className={classes.menuItem}
                            onClick={handleResolveDialog}>
                            Resolve Alert
                          </MenuItem>
                        )}
                        <MenuItem
                          className={classes.menuItem}
                          onClick={handleDeleteDialog}
                          disabled={disableDelete}>
                          Delete Alert
                        </MenuItem>
                      </Menu>
                    </Box>

                    <Box display="flex">
                      <Typography variant="body2" className={classes.alertCauseTitle}>
                        Cause: &nbsp;
                      </Typography>
                      <Typography variant="body2" className={classes.alertCause}>
                        {alert.cause}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" className={classes.alertDateTitle}>
                        Alert ID: &nbsp;
                      </Typography>
                      <Link
                        className={classes.alertIdLink}
                        component={RouterLink}
                        to={`/alert-center?ids=${alert.id}`}>
                        #{alert?.id}
                      </Link>

                      <span className={`${classes.ellipse} ${classes.firstChild}`}></span>

                      <Typography variant="body2" className={classes.alertDateTitle}>
                        Started at &nbsp;
                      </Typography>
                      <Typography variant="body2" className={classes.alertDate}>
                        {format(parseISO(alert.alertHappenedAt), "dd/MM/yyyy HH:mm 'GMT-3'")} &nbsp;
                      </Typography>

                      <span className={classes.ellipse}></span>

                      <Typography variant="body2" className={classes.alertDateTitle}>
                        Ended at: &nbsp;
                      </Typography>
                      {alert.status === StatusEnum.Resolved ? (
                        <Typography variant="body2" className={classes.alertDate}>
                          {format(parseISO(alert.updatedAt), "dd/MM/yyyy HH:mm 'GMT-3'")}
                        </Typography>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                );
              })
            )}
          </>
        )}

        <DeleteEntity
          open={openDeleteDialog}
          entityName="Alert"
          handleClose={handleDeleteDialog}
          disabled={false}
          isLoading={false}
          handleRemove={deleteAlert}
        />

        <Dialog
          open={openAcknowledgeDialog}
          onClose={handleAcknowledgeDialog}
          showCloseButton
          title="Acknowledge Alert?"
          onPrimaryAction={acknowledgeAlert}
          primaryActionLabel="Acknowledge"
          showSecondaryAction>
          <Typography variant="body1">
            Are you sure you want to proceed with the operation and confirm the incident has been
            acknowledged?
          </Typography>
          {acknowledgeAlertMutationResult.fetching ? <LoadingOverlay /> : null}
        </Dialog>

        <Dialog
          open={openResolveDialog}
          onClose={handleResolveDialog}
          showCloseButton
          title="Resolve Alert?"
          onPrimaryAction={resolveAlert}
          primaryActionLabel="Resolve"
          showSecondaryAction>
          <Typography variant="body1">
            Are you sure you want to proceed with the operation and confirm the incident has been
            resolved?
          </Typography>
          {resolveAlertMutationResult.fetching ? <LoadingOverlay /> : null}
        </Dialog>

        <Divider className={classes.alertDivider} />

        <Box display="flex" justifyContent="center">
          <Pagination
            page={page}
            count={Math.ceil(alertTotal / 10)}
            defaultPage={1}
            size="large"
            color="primary"
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
}
