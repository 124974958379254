import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';

import { IdOptions } from 'componentsV4/Filters';

import CustomAutocomplete from '../Common/customAutocompleteProps';

type HandleApplyFilters = ({ newIds }: { newIds?: IdOptions | undefined }) => void;

export const IdsFilter = ({
  ids,
  idsOptions,
  setIds,
  handleApplyFilters
}: {
  ids: IdOptions;
  idsOptions?: IdOptions;
  setIds: Dispatch<SetStateAction<IdOptions>>;
  handleApplyFilters: HandleApplyFilters;
}) => {
  const handleChangeId = (event: IdOptions) => {
    setIds(event);
    handleApplyFilters({ newIds: event });
  };

  const monitorsData = useMemo(() => {
    return idsOptions || [];
  }, [idsOptions]);

  const monitorsOptions = monitorsData.map(({ value, label }) => ({
    value,
    label
  }));

  const orderedIds = monitorsOptions.filter(
    monitor => !ids?.find((id: { value: string }) => monitor.value === id.value)
  );

  useEffect(() => {
    if (monitorsData && monitorsData.length && ids && ids.length && ids[0]?.label === '') {
      const formatedIds = ids.map(id => {
        const option = monitorsData.find(idOption => idOption?.value?.toString() === id?.value);

        return {
          label: String(option?.value) || 'Without Id',
          value: String(option?.label) || ''
        };
      });

      setIds(formatedIds);
    }
  }, [monitorsData, ids, setIds]);

  return (
    <CustomAutocomplete
      origins={ids}
      handleChangeOrigin={handleChangeId}
      orderedOrigins={orderedIds}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      label="identifiers"
    />
  );
};
