import { Fragment, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Card, Typography } from '@material-ui/core';
import { useForm, UseFormMethods } from 'react-hook-form';
import { UserProviderSettings } from 'types/external/UserProvider';

import { Button, CustomButtonProps } from 'components/Button';

import { CreateMethodDialog } from '../Dialogs/Generics/CreateMethod';
import { DeleteMethodDialog } from '../Dialogs/Generics/DeleteMethod';
import { EditMethodDialog } from '../Dialogs/Generics/EditMethod';

import { NotVerifiedWarning } from './NotVerifiedWarning';
import schema from './schema';
import { useStyles } from './styles';
import { useContactMethod } from './useContactMethod';

export type Method = 'email' | 'voice' | 'whatsapp' | 'sms' | 'telegram' | 'mobile';

export type Inputs = {
  value: string;
  method: Method;
  reusePhoneNumber?: boolean;
};

export type Contact = {
  id?: number;
  method: Method;
  value: string | null;
  active: boolean;
  verified: boolean;
  editable: boolean;
  config: UserProviderSettings['provider']['config'];
};

export type Contacts = Contact[];

interface ButtonProps extends CustomButtonProps {
  label: string;
}

type Props = {
  label?: string;
  strongLabel?: string;
  type: Method;
  contacts: Contacts;
  buttonProps?: ButtonProps;
  disableEdit?: boolean;
};

type Dialogs = Record<
  DialogActions,
  {
    [key in Method]?: (props: {
      onClose: () => void;
      form: UseFormMethods<any>;
      onSubmit: () => void;
      isCreating: boolean;
      type: any;
      strongLabel?: string;
      currentContact: Contact;
    }) => JSX.Element;
  }
>;

export type DialogActions = 'create' | 'edit' | 'delete';

const dialogs: Dialogs = {
  create: {
    whatsapp: props => <CreateMethodDialog {...props} />,
    email: props => <CreateMethodDialog {...props} />,
    telegram: props => <CreateMethodDialog {...props} />,
    voice: props => <CreateMethodDialog {...props} />,
    sms: props => <CreateMethodDialog {...props} />
  },
  edit: {
    whatsapp: props => <EditMethodDialog {...props} />,
    email: props => <EditMethodDialog {...props} />,
    telegram: props => <EditMethodDialog {...props} />,
    voice: props => <EditMethodDialog {...props} />,
    sms: props => <EditMethodDialog {...props} />
  },
  delete: {
    whatsapp: props => <DeleteMethodDialog {...props} />,
    email: props => <DeleteMethodDialog {...props} />,
    telegram: props => <DeleteMethodDialog {...props} />,
    voice: props => <DeleteMethodDialog {...props} />,
    sms: props => <DeleteMethodDialog {...props} />,
    mobile: props => <DeleteMethodDialog {...props} />
  }
};

function getEmptyContact(type: Method): Contact {
  return {
    method: type,
    value: '',
    active: false,
    verified: false,
    editable: false,
    config: {
      verified: false,
      value: ''
    }
  };
}

const ContactMethod = ({
  strongLabel,
  label,
  type,
  contacts,
  buttonProps = { label: '' }
}: Props) => {
  const { label: buttonLabel, ...restButtonProps } = buttonProps;

  const form = useForm<Inputs>({
    defaultValues: {
      value: '',
      method: type,
      reusePhoneNumber: false
    },
    resolver: zodResolver(schema)
  });

  // const [menuOptionsAnchorEl, setMenuOptionsAnchorEl] = useState<null | HTMLElement>(null);

  const [dialogAction, setDialogAction] = useState<DialogActions | undefined>();

  const classes = useStyles();

  const handleCloseDialogAction = () => {
    setDialogAction(undefined);
  };

  /* const handleOpenMenuOptions = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseMenuOptions = () => {
    setMenuOptionsAnchorEl(null);
  }; */

  const Dialog = dialogAction ? dialogs[dialogAction][type] : null;

  const { onSubmit, formatContactMethodValue, isLoading } = useContactMethod({
    contacts,
    handleCloseDialogAction
  });

  function getContactMethodDisplayValue(contact: Contact) {
    if (isMobileContactMethod) {
      const config = (contact.config as unknown) as Extract<
        UserProviderSettings['provider'],
        { provider: 'mobile' }
      >['config'];

      const { deviceName, modelName } = config;

      return `${deviceName} - ${modelName}`;
    }

    return formatContactMethodValue(contact);
  }

  const isMobileContactMethod = type === 'mobile';

  const contactMethod = isMobileContactMethod
    ? undefined
    : contacts?.find(contact => contact.method === type)!;

  const mobileContactMethods =
    type === 'mobile' ? contacts?.filter(contact => contact.method === type) : [];

  const hasMobileContactMethods = mobileContactMethods?.length > 0;

  const dialogContactMethod = contactMethod ?? getEmptyContact(type);

  const isDialogVisible = dialogAction && Dialog && dialogContactMethod;

  return (
    <>
      <Box display="flex" flexDirection="column" gridGap={12}>
        {label && <Typography className={classes.contactLabel}>{label}</Typography>}

        {!isMobileContactMethod && !contactMethod && (
          <Box>
            <Button onClick={() => setDialogAction('create')} variant="text" {...restButtonProps}>
              {buttonLabel}
            </Button>
          </Box>
        )}

        {isMobileContactMethod && !hasMobileContactMethods && (
          <Typography>App not installed on mobile devices.</Typography>
        )}

        {!isMobileContactMethod && contactMethod && (
          <Fragment key={contactMethod.id}>
            <Card className={classes.contactValueBox}>
              <Typography>{getContactMethodDisplayValue(contactMethod)}</Typography>
              {!contactMethod.verified && <NotVerifiedWarning />}

              {/*  <div className="no-print">
                <Tooltip title="More Options" arrow>
                  <Button
                    startIcon={null}
                    variant="outlined"
                    onClick={handleOpenMenuOptions}
                    className={classes.menuOptionButton}>
                    <MoreVert />
                  </Button>
                </Tooltip>
              </div> */}
            </Card>

            {!contactMethod?.verified && (
              <Box>
                <Button
                  onClick={() => setDialogAction('create')}
                  variant="text"
                  {...restButtonProps}>
                  Enter code
                </Button>
              </Box>
            )}
          </Fragment>
        )}

        {mobileContactMethods.map(contactMethod => (
          <Fragment key={contactMethod.id}>
            <Card className={classes.contactValueBox}>
              <Typography>{getContactMethodDisplayValue(contactMethod)}</Typography>
            </Card>
          </Fragment>
        ))}
      </Box>

      {isDialogVisible && (
        <Dialog
          strongLabel={strongLabel}
          onSubmit={form.handleSubmit(onSubmit)}
          currentContact={dialogContactMethod}
          form={form}
          type={type}
          isCreating={isLoading}
          onClose={handleCloseDialogAction}
        />
      )}

      {/* <ContactMethodMenu
        disableEdit={Boolean(disableEdit)}
        openDialogEdit={() => setDialogAction('edit')}
        openDialogDelete={() => setDialogAction('delete')}
        menuAnchorEl={menuOptionsAnchorEl}
        handleClose={handleCloseMenuOptions}
      /> */}
    </>
  );
};

export default ContactMethod;
