import React from 'react';

import { DialogContentText, Link } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function SlackForm({ form, startValues }: FormProps) {
  const classes = useStyles();
  const { register, errors, setValue } = form;

  const slackPopupLink = `https://slack.com/oauth/v2/authorize?client_id=${process.env['REACT_APP_SLACK_CLIENT_ID_1P']}&scope=incoming-webhook,chat:write.public,channels:manage,chat:write&user_scope=&redirect_uri=${window.location.protocol}//${window.location.host}/teams/${startValues?.teamId}/slack-confirmation`;

  const focusEventListener = React.useCallback(() => {
    const slackIntegrationString = sessionStorage.getItem('slackIntegration');
    const slackIntegration = slackIntegrationString ? JSON.parse(slackIntegrationString) : null;

    if (slackIntegration) {
      setValue('config.slackIntegration', slackIntegrationString, { shouldDirty: true });
      setValue('config.slackWorkspace', slackIntegration?.team?.name);
      setValue('config.slackChannel', slackIntegration?.incoming_webhook?.channel);

      sessionStorage.removeItem('slackIntegration');
    }
  }, [setValue]);

  React.useEffect(() => {
    window.addEventListener('focus', focusEventListener);

    return () => {
      window.removeEventListener('focus', focusEventListener);
    };
  }, [focusEventListener]);

  const SlackInstall = ({ children }: { children: React.ReactNode }) => (
    <Link
      href={slackPopupLink}
      target="popup"
      onClick={() => {
        window.open(slackPopupLink, 'popup', 'width=600,height=600');
        return false;
      }}>
      {children}
    </Link>
  );

  return (
    <>
      <DialogContentText>
        1.&nbsp;
        {!startValues?.config?.slackWorkspace && !startValues?.config?.slackChannel
          ? 'Install'
          : 'Reinstall'}{' '}
        our <SlackInstall>Slack App</SlackInstall> first
      </DialogContentText>

      <DialogContentText>Go to </DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.slackIntegration"
          style={{ display: 'none' }}
        />
      </DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.slackWorkspace"
          label="Slack workspace"
          errors={errors}
          required
        />
      </DialogContentText>
      <DialogContentText>on</DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.slackChannel"
          label="Slack channel"
          errors={errors}
          required
        />
      </DialogContentText>
    </>
  );
}
