import { Box, CardContent, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useGetIncidentResponders } from 'hooks/queriesGraphQL/useGetIncidentResponders';

import { useStyles } from './styles';

type IncidentRespondersProps = {
  incidentId: number;
};

function IncidentResponders({ incidentId }: IncidentRespondersProps) {
  const classes = useStyles();
  const theme = useTheme();

  const { data: incidentResponders, isLoading } = useGetIncidentResponders(incidentId);

  const hasData = incidentResponders.length > 0;

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} variant="subtitle2">
          Responders
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />

        <Box
          className={clsx(classes.scrollBar, !hasData && classes.noData)}
          position="relative"
          gridGap={theme.spacing()}>
          {incidentResponders.map(responder => (
            <Box display="flex" gridGap={theme.spacing()} key={responder.id}>
              <Typography variant="body1" style={{ lineHeight: 'inherit' }}>
                {responder.name}
                <Typography display="block" variant="caption" color="primary">
                  {responder.type}
                </Typography>
              </Typography>
            </Box>
          ))}

          {!hasData && !isLoading && (
            <Typography color="textPrimary" variant="h6">
              No Responders
            </Typography>
          )}

          {isLoading && <LoadingOverlay size={theme.spacing(4)} />}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default IncidentResponders;
