import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(13),
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(12),
    paddingRight: theme.spacing(13)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2),
    borderRadius: 8,
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  cardContent: {
    width: '100%',
    height: '100%',
    padding: 0
  },
  titleCard: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  dividerSections: {
    opacity: '30%',
    backgroundColor: theme.palette.text.hint
  },
  noData: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1
  },
  scrollBar: {
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
    maxHeight: 120,
    scrollbarWidth: 'auto',
    overflowY: 'auto',
    paddingTop: theme.spacing(),
    scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.grey[100]}`,
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5)
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[100]
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.grey[300]
    }
  }
}));
