import { useQuery } from 'urql';

export type IncidentTags = {
  id: number;
  incidentId: number;
  name: string;
  value: string;
};

const GetIncidentTagsQuery = `#graphql
    query ($incidentId: Int!) {
      getIncidentTags(incidentId: $incidentId) {
        id
        incidentId
        name
        value
      }
    }
  `;

export function useGetIncidentTags(incidentId: number) {
  const [{ data, fetching }] = useQuery<{ getIncidentTags: IncidentTags[] }>({
    query: GetIncidentTagsQuery,
    variables: { incidentId }
  });

  return { data: data?.getIncidentTags ?? [], isLoading: fetching };
}
