import { useState } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@material-ui/core';
import { Add as AddIcon, Delete, GitHub, Refresh, Edit, OpenInNew } from '@material-ui/icons';
import { ButtonDefault } from 'oldComponents/Button/index';
import DeleteDialogV2 from 'oldComponents/DeleteDialogV2';
import { useHistory } from 'react-router-dom';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import JiraIcon from 'componentsV4/SelectIntegration/icons/Jira';
import ServiceNowIcon from 'componentsV4/SelectIntegration/icons/ServiceNow';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useCommunicationIntegrations from 'hooks/queriesGraphQL/useCommunicationIntegrations';
import useItsmIntegration from 'hooks/queriesGraphQL/useItsmIntegration';
import useLeadTimeChanges, {
  ConnectedApplications,
  Integration
} from 'hooks/queriesGraphQL/useLeadTimeChanges';
import Bitbucket from 'icons/Bitbucket';
import GitLab from 'icons/GitLab';
import SlackColouredIcon from 'icons/SlackColoured';

import ConnectedApplicationData from './ConnectedApplicationData';
import IntegrationEditDialog from './EditDialog';
import NoConnectedApplicationsData from './NoConnectedApplicationsData';
import { useStyles } from './styles';
import cardMessages from './utils/cardMessages';

export type IntegrationCardPlatforms =
  | 'Github'
  | 'Gitlab'
  | 'Bitbucket'
  | 'ServiceNow'
  | 'Slack'
  | 'Jira';

type IntegrationCardProps = {
  data: Integration[];
  isCreatingIntegration: boolean;
  isFetchingIntegrations: boolean;
  platform: IntegrationCardPlatforms;
  onDeleteIntegration: () => void;
  isItsmIntegration?: boolean;
};

const getCardIcon = (value: string): JSX.Element => {
  const icons: { [key: string]: JSX.Element } = {
    Bitbucket: <Bitbucket fontSize="large" />,
    Github: <GitHub fontSize="large" />,
    Gitlab: <GitLab fontSize="large" />,
    ServiceNow: <ServiceNowIcon width="33" height="28" fontSize="large" />,
    Jira: <JiraIcon width="33" height="28" fontSize="large" />,
    Slack: <SlackColouredIcon width="33" height="28" fontSize="large" />
  };

  return icons[value];
};

const getIntegrationAction = (platform: IntegrationCardProps['platform']) => {
  const actions: { [key: string]: () => void } = {
    Github: () => {
      const githubIntegrationUrl = process.env.REACT_APP_GITHUB_INTEGRATION_URL;
      window.open(githubIntegrationUrl, '_self');
    },
    Bitbucket: () => {
      const integrationPage = process.env.REACT_APP_BITBUCKET_INTEGRATION_URL;
      window.open(integrationPage, '_self');
    },
    ServiceNow: () => {
      window.open('/services-hub/new?type=itsm-service-now');
    },
    Jira: () => {
      window.open('/services-hub/new?type=itsm-jira');
    },
    Slack: () => {
      window.open(
        `https://slack.com/oauth/v2/authorize?scope=channels:manage&user_scope=&redirect_uri=${window.location.protocol}//${window.location.host}/slack-integration-done&client_id=${process.env['REACT_APP_SLACK_CLIENT_ID_1P']}`,
        'popup',
        'width=600,height=600'
      );
    }
  };

  return actions[platform];
};

const getIntegrationPageAction = ({
  platform,
  data
}: {
  platform: IntegrationCardPlatforms;
  data: {
    installationId?: number;
    accountName: string;
    team: {
      id: number;
    };
  };
}) => {
  const pages: { [key: string]: () => void } = {
    Github: () => {
      const integrationPage = `https://github.com/organizations/${data.accountName}/settings/installations/${data.installationId}`;
      window.open(integrationPage, '_blank');
    },
    Bitbucket: () => {
      const integrationPage = `https://bitbucket.org/${data.accountName}/workspace/settings/addon-management`;
      window.open(integrationPage, '_blank');
    },
    Slack: () => {
      const integrationPage = `https://app.slack.com/client/${data.team.id}`;
      window.open(integrationPage, '_blank');
    }
  };

  return pages[platform];
};

export function IntegrationCard({
  data = [],
  isCreatingIntegration,
  isFetchingIntegrations,
  platform,
  onDeleteIntegration,
  isItsmIntegration
}: IntegrationCardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const isCommunicationIntegration = platform === 'Slack';
  const showInstallIntegrationButton = isCommunicationIntegration ? !data.length : true;
  const showConnectIntegrationButton = !(isItsmIntegration || isCommunicationIntegration);

  const { deleteIntegration, deleteAppAssociation } = useLeadTimeChanges();
  const { deleteItsmIntegration } = useItsmIntegration();
  const { deleteCommunicationIntegration } = useCommunicationIntegrations();

  const [{ fetching: isDeletingIntegration }, deleteIntegrationMutation] = deleteIntegration;
  const [
    { fetching: isDeletingItsmIntegration },
    deleteItsmIntegrationMutation
  ] = deleteItsmIntegration;
  const [
    { fetching: isDeletingCommunicationIntegration },
    deleteCommunicationIntegrationMutation
  ] = deleteCommunicationIntegration;
  const [
    { fetching: isDeletingAppAssociation },
    deleteAppAssociationMutation
  ] = deleteAppAssociation;

  const idDeleting =
    isDeletingCommunicationIntegration || isDeletingIntegration || isDeletingItsmIntegration;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteAppAssociationDialogOpen, setIsDeleteAppAssociationDialogOpen] = useState(false);

  const [deleteDialogData, setDeleteDialogData] = useState<{
    integrationId?: number | null;
    integrationUid?: string | null;
  }>({ integrationId: null, integrationUid: null });

  const [editDialogData, setEditDialogData] = useState<{
    repositoryAccess: string;
    accountName: string;
    connectedApplications: ConnectedApplications;
  }>({ accountName: '', repositoryAccess: '', connectedApplications: [] });

  const [deleteAppAssociationDialogData, setDeleteAppAssociationDialogData] = useState<{
    applicationUid: string | null;
  }>({ applicationUid: null });

  const isLoading = isCreatingIntegration || isFetchingIntegrations;
  const hasData = data.length > 0;

  function handleEditIntegration(data: {
    connectedApplications: ConnectedApplications;
    repositoryAccess: string;
    accountName: string;
    integrationUid?: string;
    origin?: string;
  }) {
    if (data?.integrationUid && data?.origin === 'service-now') {
      window.open(`/services-hub/edit/${data.integrationUid}?type=itsm-service-now`);
      return;
    }
    if (data?.integrationUid && data?.origin === 'jira') {
      window.open(`/services-hub/edit/${data.integrationUid}?type=itsm-jira`);
      return;
    }
    setEditDialogData(data);
    setIsEditDialogOpen(true);
  }

  function handleDeleteIntegration(integration: { id?: number; uid?: string }) {
    setDeleteDialogData({ integrationId: integration.id, integrationUid: integration?.uid });
    setIsDeleteDialogOpen(true);
  }

  async function deleteAppAssociationConfirm() {
    await deleteAppAssociationMutation({
      applicationUid: deleteAppAssociationDialogData.applicationUid
    });

    setIsDeleteAppAssociationDialogOpen(false);

    onDeleteIntegration();
  }

  async function deleteIntegrationConfirm() {
    if (!deleteDialogData.integrationUid && !deleteDialogData.integrationId) {
      deleteCommunicationIntegrationMutation({}, injectAuditTrailMetadata({ name: platform }));
    }
    if (deleteDialogData.integrationUid) {
      await deleteItsmIntegrationMutation(
        { integrationUid: deleteDialogData.integrationUid },
        injectAuditTrailMetadata({
          name: platform,
          integrationUid: deleteDialogData.integrationUid
        })
      );
    }
    if (deleteDialogData.integrationId) {
      await deleteIntegrationMutation(
        { integrationId: deleteDialogData.integrationId },
        injectAuditTrailMetadata({
          name: platform,
          integrationId: deleteDialogData.integrationId.toString()
        })
      );
    }

    setIsDeleteDialogOpen(false);

    onDeleteIntegration();
  }

  return (
    <>
      <DeleteDialogV2
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onPrimaryAction={deleteIntegrationConfirm}
        isLoading={idDeleting}
        title="Delete integration?"
        primaryActionLabel="Delete integration"
        message={
          isItsmIntegration
            ? 'Are you sure you want to delete Servicenow integration? By proceeding with the action, the integration will be deleted.'
            : 'By deleting this integration, all connections to the applications will also be deleted, erasing the Lead Time for Changes metrics.'
        }
      />
      <IntegrationEditDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        connectedApplications={editDialogData.connectedApplications}
        onPrimaryAction={applicationUid => {
          setIsEditDialogOpen(false);
          setDeleteAppAssociationDialogData({ applicationUid });
          setIsDeleteAppAssociationDialogOpen(true);
        }}
        isLoading={isDeletingAppAssociation}
        accountName={editDialogData.accountName}
        repositoryAccess={editDialogData.repositoryAccess}
      />
      <DeleteDialogV2
        open={isDeleteAppAssociationDialogOpen}
        onClose={() => {
          setIsDeleteAppAssociationDialogOpen(false);
          setIsEditDialogOpen(true);
        }}
        onPrimaryAction={deleteAppAssociationConfirm}
        isLoading={isDeletingAppAssociation}
        title="Delete connection?"
        primaryActionLabel="Delete connection"
        message="By deleting the application's connection to the integration, the Lead Time for Changes metrics were no longer calculated."
      />

      <Card classes={{ root: classes.borderBody }}>
        <CardContent>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1.5)} marginLeft="10px">
            {getCardIcon(platform)}
            <Typography
              variant="h3"
              style={{
                fontSize: theme.typography.pxToRem(18)
              }}>
              {platform} Integration
            </Typography>
          </Box>

          <Typography className={classes.text}>{cardMessages[platform]({ platform })}</Typography>

          {isLoading && (
            <Box display="flex" justifyContent="center" gridGap={theme.spacing(2)}>
              <LoadingOverlay isBlock size={theme.typography.pxToRem(18)} thickness={5} />
              <Typography variant="caption" color="primary">
                {isCreatingIntegration && `Setting up ${platform} integration...`}
                {isFetchingIntegrations && `Fetching ${platform} integrations...`}
              </Typography>
            </Box>
          )}

          {!isLoading && (
            <>
              {showInstallIntegrationButton && (
                <CardActions>
                  <ButtonDefault
                    size="medium"
                    variant="text"
                    children="Install new integration"
                    startIcon={<AddIcon />}
                    onClick={getIntegrationAction(platform)}
                  />
                </CardActions>
              )}
              <Divider className={classes.diviserLine} variant="middle" />
              <TableContainer>
                <Table>
                  {hasData && (
                    <TableHead>
                      <TableRow>
                        {isItsmIntegration && (
                          <TableCell className={classes.titleCell} align="left">
                            Domain
                          </TableCell>
                        )}

                        {isCommunicationIntegration && (
                          <TableCell className={classes.titleCell} align="left">
                            Workspace
                          </TableCell>
                        )}

                        {!isItsmIntegration && !isCommunicationIntegration && (
                          <>
                            <TableCell className={classes.titleCell} align="left">
                              Account
                            </TableCell>
                            <TableCell className={classes.titleCell} align="left">
                              Repositories Access
                            </TableCell>
                            <TableCell className={classes.titleCell} align="left">
                              Connected Applications
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {hasData ? (
                      data.map((integration: any) => (
                        <TableRow key={integration?.accountName || integration?.uid}>
                          {isItsmIntegration && (
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              component="th"
                              scope="row">
                              {integration?.configuration?.subdomain}
                            </TableCell>
                          )}
                          {integration?.integrationPayload?.team && (
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              component="th"
                              scope="row">
                              {integration?.integrationPayload?.team?.name}
                            </TableCell>
                          )}
                          {!isItsmIntegration && !integration?.integrationPayload?.team && (
                            <>
                              <TableCell
                                className={classes.tableCell}
                                align="left"
                                component="th"
                                scope="row">
                                {integration.accountName}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="left">
                                {integration.repositoryAccess}
                              </TableCell>
                              <TableCell className={classes.tableCell} align="left">
                                {integration.connectedApplications.length > 0 ? (
                                  <ConnectedApplicationData
                                    connectedApplications={integration.connectedApplications.map(
                                      (integration: any) => integration.name
                                    )}
                                  />
                                ) : (
                                  <NoConnectedApplicationsData
                                    platform={platform}
                                    integrationId={integration.id}
                                  />
                                )}
                              </TableCell>
                            </>
                          )}

                          <TableCell className={classes.actionsButtons} align="right">
                            {!isItsmIntegration && (
                              <TableCell className={classes.tableCell} align="left">
                                <IconButton
                                  style={{ margin: 0 }}
                                  title={`View integration on ${platform}`}
                                  onClick={getIntegrationPageAction({
                                    platform,
                                    data: {
                                      installationId: integration?.data?.installationId,
                                      accountName: integration.accountName,
                                      team: {
                                        id: integration?.integrationPayload?.team?.id
                                      }
                                    }
                                  })}>
                                  <OpenInNew className={classes.iconColor} />
                                </IconButton>
                              </TableCell>
                            )}

                            <TableCell className={classes.tableCell} align="left">
                              {isCommunicationIntegration ? (
                                <IconButton
                                  style={{ margin: 0 }}
                                  disabled={isLoading}
                                  onClick={onDeleteIntegration}>
                                  <Refresh
                                    className={classes.iconColor}
                                    data-disabled={isLoading}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  style={{ margin: 0 }}
                                  disabled={integration?.connectedApplications?.length === 0}
                                  onClick={() =>
                                    handleEditIntegration({
                                      connectedApplications: integration?.connectedApplications,
                                      repositoryAccess: integration?.repositoryAccess,
                                      accountName: integration?.accountName,
                                      integrationUid: integration?.uid,
                                      origin: integration?.origin
                                    })
                                  }>
                                  <Edit
                                    className={classes.iconColor}
                                    data-disabled={integration?.connectedApplications?.length === 0}
                                  />
                                </IconButton>
                              )}
                            </TableCell>

                            <TableCell className={classes.tableCell} align="left">
                              <IconButton
                                style={{ margin: 0 }}
                                onClick={() =>
                                  handleDeleteIntegration({
                                    id: integration?.id,
                                    uid: integration?.uid
                                  })
                                }>
                                <Delete className={classes.iconColor} />
                              </IconButton>
                            </TableCell>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell className={classes.tableCell} align="left" colSpan={4}>
                          No integrations found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {hasData && showConnectIntegrationButton && (
                <ButtonDefault
                  className={classes.spaceButton}
                  size="small"
                  children="Connect application"
                  variant="contained"
                  onClick={() => history.push(`/integrations/new/${platform.toLowerCase()}`)}
                />
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
