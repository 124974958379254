import React from 'react';

import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';

import { Recurrence, EndOfRecurrence } from '../../../../../constants/callRotationRecurrence';
import StepsGroups from '../../CallRotation/StepsGroups';

import { getDaysArray } from './Calendar';

const useStyles = makeStyles(theme => ({
  schedulePreviewHeader: {
    marginTop: theme.spacing(1)
  },
  schedulePreviewDivider: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const FormCreate = ({
  timeSelected,
  setTimeSelected,
  dataSelected,
  setDataSelected,
  color,
  setColor,
  setGroup,
  teamId,
  recurrence,
  setRecurrence,
  endOfRecurrence,
  setEndOfRecurrence,
  dataInitSelected,
  dataEndSelected,
  refetchSchedules,
  permissions,
  setGroupName
}) => {
  const classes = useStyles();

  const handleChangeTime = event => {
    event.persist();
    setTimeSelected(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const [disabled, setDisabled] = React.useState(true);
  const [allDay, setAllDay] = React.useState(false);

  const handleRecurrence = event => {
    setRecurrence(event.target.value);

    setDataSelected(
      getDaysArray(
        { start: dataInitSelected, end: dataEndSelected },
        event.target.value,
        endOfRecurrence
      )
    );

    if (event.target.value === Recurrence.DoesNotRepeat) {
      setDisabled(true);
      setEndOfRecurrence(EndOfRecurrence.AfterAMonth);
    } else {
      setDisabled(false);
    }
  };

  const handleEndOfRecurrence = event => {
    setEndOfRecurrence(event.target.value);
    setDataSelected(
      getDaysArray(
        { start: dataInitSelected, end: dataEndSelected },
        recurrence,
        event.target.value
      )
    );
  };

  const handleAllDay = event => {
    setAllDay(event.target.checked);
    if (event.target.checked) {
      setTimeSelected({ start: '00:00', end: '23:59' });
    } else {
      setTimeSelected({ start: '00:00', end: '23:59' });
    }
  };

  const getSchedulePreview = () => {
    let dateInit = dayjs(dataInitSelected);

    let dateEnd = dayjs(
      dayjs(dataSelected[dataSelected.length - 1]).format('YYYY/MM/DD') + ' ' + timeSelected.end
    );

    dateInit = dateInit.format('DD/MM/YYYY');
    dateEnd = dateEnd.format('DD/MM/YYYY');

    const hourInit = dayjs(dataSelected[0] + ' ' + timeSelected.start).format('HH:mm');
    const hourEnd = dayjs(dataSelected[dataSelected.length - 1] + ' ' + timeSelected.end).format(
      'HH:mm'
    );

    if (endOfRecurrence === EndOfRecurrence.Never) {
      return `from ${dateInit} - (${hourInit} ~ ${hourEnd})`;
    }

    return `from ${dateInit} to ${dateEnd} - (${hourInit} ~ ${hourEnd})`;
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={5} xs={11}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          type="time"
          label="Start time"
          margin="dense"
          name="start"
          onChange={handleChangeTime}
          disabled={allDay || !permissions?.newCallRotation}
          value={timeSelected.start || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={5} xs={11}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          type="time"
          label="End time"
          margin="dense"
          name="end"
          onChange={handleChangeTime}
          disabled={allDay || !permissions?.newCallRotation}
          value={timeSelected.end || ''}
          variant="outlined"
        />
      </Grid>

      <Grid item style={{ marginTop: '5px', marginLeft: 15 }}>
        <FormControlLabel
          style={{ padding: 0, margin: 0 }}
          control={
            <GreenCheckbox
              style={{ margin: 0 }}
              checked={allDay}
              onChange={handleAllDay}
              name="checkedG"
              disabled={!permissions?.newCallRotation}
            />
          }
          label="All Day"
        />
      </Grid>

      <Grid item xs={12} style={{ display: 'flex' }}>
        <Box minWidth={360} marginRight={1}>
          <StepsGroups
            fetchSchedulesList={refetchSchedules}
            onChange={value => {
              setGroup(value ? value.id : '');
              setGroupName(value ? value.name : '');
            }}
            permissions={permissions}
          />
        </Box>
        <TextField
          fullWidth
          disabled={!permissions.newCallRotation}
          InputLabelProps={{
            shrink: true
          }}
          type="color"
          label="Event color"
          margin="dense"
          name="color"
          onChange={event => setColor(event.target.value)}
          value={color}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', marginBottom: 12 }}>
        <Box style={{ paddingLeft: '5px' }}>
          <Typography>
            <Link component={RouterLink} to={`/teams/${teamId}/stepGroups`}>
              View your Step Groups
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">Recurrence</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Recurrence"
            value={recurrence}
            onChange={handleRecurrence}
            name="recurrence"
            disabled={permissions?.newCallRotation ? !permissions?.newCallRotation : true}>
            <MenuItem value={Recurrence.DoesNotRepeat}>Does not Repeat</MenuItem>
            <MenuItem value={Recurrence.Weekly}>Weekly</MenuItem>
            <MenuItem value={Recurrence.Biweekly}>Biweekly</MenuItem>
            <MenuItem value={Recurrence.Triweekly}>Triweekly</MenuItem>
            <MenuItem value={Recurrence.Quadweekly}>Quadweekly</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">End of Recurrence</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="End of Recurrence"
            disabled={disabled && !permissions?.newCallRotation}
            value={endOfRecurrence}
            onChange={handleEndOfRecurrence}
            name="endOfRecurrence">
            <MenuItem value={EndOfRecurrence.AfterAMonth}>After a month</MenuItem>
            <MenuItem value={EndOfRecurrence.AfterTwoMonths}>After two months</MenuItem>
            <MenuItem value={EndOfRecurrence.AfterThreeMonths}>After three months</MenuItem>
            <MenuItem value={EndOfRecurrence.AfterSixMonths}>After six months</MenuItem>
            <MenuItem value={EndOfRecurrence.AfterAYear}>After a year</MenuItem>
            <MenuItem value={EndOfRecurrence.Never}>Never</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} className={classes.schedulePreviewHeader}>
        <Typography variant="h5" gutterBottom>
          Schedule preview:
        </Typography>
        <Typography>
          Will call team members for incidents that occur during those time intervals
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.schedulePreviewDivider}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography>{getSchedulePreview()}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.schedulePreviewDivider}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default FormCreate;
