import { useEffect, useRef, useState } from 'react';

import { Box, CardContent, Divider, Grid, Link, Paper, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import ImpactedOrigins from 'oldComponents/ImpactedOrigins';
import ImpactedResources from 'oldComponents/ImpactedResources';
import IncidentUpdate from 'oldComponents/IncidentUpdate';
import { useParams } from 'react-router-dom';
import { StatusEnum } from 'types/external/Status';
import { useSubscription } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import IncidentAlerts from 'components/IncidentAlerts';
import IncidentResponders from 'components/IncidentResponders';
import IncidentTags from 'components/IncidentTags';
import { useGetIncidentTags } from 'hooks/queriesGraphQL/useGetIncidentTags';
import useIncident from 'hooks/queriesGraphQL/useIncident';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import IncidentError from '../../Components/IncidentError';
import LoadingSkeleton from '../../Components/LoadingSkeleton';
import IncidentForm from '../IncidentForm';

import Actions from './Actions';
import CardTimeInfo from './CardTimeInfo';
import PostMortemFormIndex from './PostMortemForm';
import useStyles from './styles';

type QueryParams = Record<'incidentId', string>;

const View = () => {
  const { incidentId } = useParams<QueryParams>();

  const [resultIncident, fetchingIncident, onReexecuteQuery] = useIncident({
    incidentId: Number(incidentId)
  });

  const [incidentState, setIncidentState] = useState({});

  useSubscription<void>(
    {
      query: `#graphql
        subscription ($incidentId: Float!) {
          incidentsStateUpdated (incidentId: $incidentId) {
            status
            metrics {
              tta
              ttrecov
            }
            warRooms {
              type
              uid
            }
            updatedAt
            __typename
          }
        }
      `,
      variables: { incidentId: Number(incidentId) }
    },
    (current, newData: any) => {
      let warRom = {};

      if (newData.incidentsStateUpdated?.warRooms?.length) {
        warRom = {
          warRoomProvider: newData.incidentsStateUpdated?.warRooms[0]?.type,
          warRoomIntegrationId: Number(newData.incidentsStateUpdated?.warRooms[0]?.uid)
        };
      }

      setIncidentState({
        updatedAt: newData.incidentsStateUpdated?.updatedAt,
        mtta:
          newData.incidentsStateUpdated?.metrics?.tta > 0
            ? newData.incidentsStateUpdated?.metrics?.tta
            : null,
        mttRecov:
          newData.incidentsStateUpdated?.metrics?.ttrecov > 0
            ? newData.incidentsStateUpdated?.metrics?.ttrecov
            : null,
        ...warRom,
        ...newData.incidentsStateUpdated
      });
    }
  );

  const classes = useStyles();

  const incidentUpdateRef = useRef<HTMLDivElement>(null);
  const incidentInfoRef = useRef<HTMLDivElement>(null);
  const postMortemRef = useRef<HTMLDivElement>(null);

  const isImpactedResourcesEnabled = useFeatureFlagUnleash('impactedResources');
  const showIncidentResponders = useFeatureFlagUnleash('incidentResponders');
  const showIncidentAlerts = useFeatureFlagUnleash('incidentAlerts');
  const usePostmortemJourney = useFeatureFlagUnleash('postmortemJourney');
  const shouldUseNewIncidentDate = useFeatureFlagUnleash('newIncidentDate');
  const shouldUseEditIncidentForm = useFeatureFlagUnleash('useEditIncidentForm');
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { data: incidentTags } = useGetIncidentTags(Number(incidentId));

  useEffect(() => {
    setIncidentState({});
  }, [incidentId]);

  if (resultIncident?.error) {
    return <IncidentError />;
  }

  if (fetchingIncident && !resultIncident) {
    return <LoadingSkeleton />;
  }

  if (!resultIncident) {
    return <LoadingSkeleton />;
  }

  const incident = { ...resultIncident.incident, ...incidentState };
  const isManagedIncident = incident.type === 'managed';

  const showLinkToSource =
    isManagedIncident && incident?.data !== null && incident?.data?.source !== '';

  const linkToSource = incident?.data?.source;

  const hasTags = incidentTags.length > 0;

  const reexecuteIncidentQuery = () => {
    onReexecuteQuery();
  };

  const getStatus = (incident: any): string => {
    if (incident.acknowledged && !incident.closed) {
      return 'acknowledged';
    }

    return String(incident?.status);
  };

  const endedAt = incident.status === 'resolved' ? incident.updatedAt : null;

  return (
    <>
      <Box className={classes.breadcrumb}>
        <Breadcrumb
          items={[{ label: 'Incident Center', link: '/incidents' }, { label: incident.title }]}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={5}>
        <StatusChip type="Severity" severityStatus={incident.severity} isAlertStatus={false} />
        <StatusChip type="Event" status={getStatus(incident) as StatusEnum} isAlertStatus={true} />
        <Typography variant="h4">{incident.title}</Typography>
      </Box>

      <Divider className={classes.dividerSections} />

      <Actions
        incident={incident}
        onAcknowledgeOrResolved={() => {
          reexecuteIncidentQuery();
        }}
        incidentInfoRef={incidentInfoRef}
        incidentUpdateRef={incidentUpdateRef}
        postMortemRef={postMortemRef}
      />

      <Grid container spacing={3}>
        <CardTimeInfo
          title="Started at"
          value={!shouldUseNewIncidentDate ? incident?.createdAt : incident?.failureHappenedAt}
          isTime={false}
        />
        <CardTimeInfo title="Time to Acknowledged" value={incident.mtta} isTime={true} />
        <CardTimeInfo title="Time to Resolved" value={incident?.mttRecov} isTime={true} />
        <CardTimeInfo title="Ended at" value={endedAt} isTime={false} />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CardContent className={classes.cardContent}>
            <Paper elevation={1} className={classes.paper}>
              <Box display="flex">
                <Typography
                  className={classes.titleCard}
                  variant="subtitle2"
                  style={{ flexGrow: 1 }}>
                  Cause
                </Typography>

                {showLinkToSource && (
                  <Link style={{ display: 'flex' }} href={linkToSource}>
                    <OpenInNew className={classes.spaceToSourceLink} />
                    <Typography variant="body1" className={classes.colorLink}>
                      Source
                    </Typography>
                  </Link>
                )}
              </Box>

              <Typography className={classes.description} variant="body1">
                {incident?.description}
              </Typography>
            </Paper>
          </CardContent>
        </Grid>

        {isImpactedResourcesEnabled && (
          <Grid item xs={!hasTags ? 12 : 6}>
            {newApplications ? (
              <ImpactedOrigins incidentId={incident.incidentId} />
            ) : (
              <ImpactedResources incidentId={incident.incidentId} />
            )}
          </Grid>
        )}

        {hasTags && (
          <Grid item xs={6}>
            <IncidentTags tags={incidentTags} />
          </Grid>
        )}

        {showIncidentResponders && (
          <Grid item xs={6}>
            <IncidentResponders incidentId={incident.incidentId} />
          </Grid>
        )}

        {showIncidentAlerts && (
          <Grid item xs={6}>
            <IncidentAlerts incidentId={incident.incidentId} />
          </Grid>
        )}
      </Grid>

      <div ref={incidentUpdateRef}>
        <IncidentUpdate incident={incident} />
      </div>

      {shouldUseEditIncidentForm && (
        <>
          <Divider className={classes.dividerSections} />
          <div ref={incidentInfoRef}>
            <IncidentForm
              onIncidentSave={reexecuteIncidentQuery}
              incident={incident}
              descriptionRef={incidentInfoRef}
            />
          </div>
        </>
      )}

      {!usePostmortemJourney && (
        <>
          <Divider className={classes.dividerSections} />
          <Grid item xs={12}>
            <PostMortemFormIndex
              onPostMortemSave={reexecuteIncidentQuery}
              incident={incident}
              postMortemRef={postMortemRef}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default View;
