import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentGrafanaGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Grafana',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrls: [
      {
        key: 'Grafana V10',
        url: `https://${getEnvUrl()}/${orgUid}/incidents/v1/grafana/v10/${integrationUid}?token=${token}`
      },
      {
        key: 'Grafana V8',
        url: `https://${getEnvUrl()}/${orgUid}/incidents/v1/grafana/v8/${integrationUid}?token=${token}`
      }
    ],
    instructions: {
      isNumeric: false,
      steps: [
        'Use this url to create incidents via Grafana.',
        'PS: Check the grafana version and use the corresponding url'
      ]
    },
    snippetWarning: {
      message: 'For more information, check out our ',
      linkHref:
        'https://docs.elven.works/guia-configuracao-do-external-service-incident-grafana-na-elven-platform/',
      linkText: 'documentation.'
    }
  };
};
