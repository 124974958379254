import { useState } from 'react';

import { Button, Menu as MUIMenu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { useStyles } from './style';

const DeleteIncidentMutation = `#graphql
  mutation ($incidentId: Float!){
    deleteIncident(incidentId: $incidentId)
  }
`;
const DeleteIncidentMutationV2 = `#graphql
  mutation ($incidentId: Float!){
    deleteIncidentV2(incidentId: $incidentId)
  }
`;

export type Incident = {
  incidentId: number;
  type: string;
  title: string;
};

interface Props {
  incident: Incident;
  reexecuteQuery: () => void;
}

const IncidentMenu = ({ incident, reexecuteQuery }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const shouldUseIncidentManualWithAlerts = useFeatureFlagUnleash('useIncidentManualWithAlerts', {
    queryString: true
  });

  const [deleteIncidentId, setDeleteIncidentId] = useState<{
    incidentId: number | undefined;
  }>({ incidentId: 0 });

  const [incidentType, setDeleteIncidentType] = useState<{
    incidentType: string;
  }>({ incidentType: '' });

  const [incidentTitle, setDeleteIncidentTitle] = useState<{
    incidentTitle: string;
  }>({ incidentTitle: '' });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const [, deleteIncidentMutation] = useMutation(
    shouldUseIncidentManualWithAlerts ? DeleteIncidentMutationV2 : DeleteIncidentMutation
  );

  const deleteIncident = () => {
    deleteIncidentMutation(
      { incidentId: deleteIncidentId.incidentId },
      injectAuditTrailMetadata({
        title: incidentTitle.incidentTitle,
        incidentId: deleteIncidentId.incidentId!.toString()
      })
    ).then(() => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Incident deleted successfully'
      });

      setTimeout(() => reexecuteQuery(), 2000);
    });

    setOpenDeleteDialog(false);
  };

  const handleToggleMenu = (
    event: React.MouseEvent<HTMLElement>,
    incidentId: number,
    incidentType: string,
    incidentTitle: string
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setDeleteIncidentId({ incidentId });
    setDeleteIncidentType({ incidentType });
    setDeleteIncidentTitle({ incidentTitle });
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
    handleCloseMenu();
  };

  const handleOpenEdit = (incidentId: number) => {
    history.push(`services-hub/edit/${incidentId}?type=incident-manual`);
    return;
  };

  const canEdit = usePermission('ServicesHubController-put-/services');
  const canDelete = usePermission('IncidentController-delete-/incident/:id');

  return (
    <>
      <Button
        className={classes.menuButton}
        variant="outlined"
        onClick={e => handleToggleMenu(e, incident.incidentId, incident.type, incident.title)}>
        <MoreVert />
      </Button>

      <MUIMenu
        open={isMenuOpen}
        elevation={1}
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.paperProps }}>
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleOpenEdit(incident.incidentId)}
          disabled={incidentType.incidentType !== 'manual' || !canEdit}>
          Edit Incident
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeleteDialog}
          disabled={incidentType.incidentType === 'hit/fail' || !canDelete}>
          Delete Incident
        </MenuItem>
      </MUIMenu>
      <DeleteEntity
        open={openDeleteDialog}
        entityName="Incident"
        handleClose={handleDeleteDialog}
        disabled={false}
        isLoading={false}
        handleRemove={deleteIncident}
      />
    </>
  );
};

export default IncidentMenu;
