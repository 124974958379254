import { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import useGetTeam from 'hooks/queriesGraphQL/useGetTeam';
import { TeamNotifySetting } from 'hooks/queriesGraphQL/useGetTeamNotifySettings';
import actions from 'redux/actions';

import NotificationSwitchBase from '../NotificationSwitchBase';

const updateTeamNotifySettingsMutation = `#graphql
  mutation ($id: Float!, $teamId: Float!, $enabled: Boolean!) {
    updateTeamNotifySettings(id: $id, teamId: $teamId, enabled: $enabled) {
      success
      message
    }
}
`;

type NotifySettingsSwitchProps = {
  title: string;
  teamsSettings: TeamNotifySetting;
  teamId: number;
  refetch: () => void;
};

const ENABLE_MESSAGE =
  'By activating this event, you will receive notifications whenever an alert or incident occurs during your on-call shift.';
const DISABLE_MESSAGE =
  'By deactivating this event, you will stop receiving notifications whenever an alert or incident occurs during your on-call shift.';

export default function NotifySettingsSwitch({
  title,
  teamsSettings,
  teamId,
  refetch
}: NotifySettingsSwitchProps) {
  const [checked, setChecked] = useState<boolean>(teamsSettings.enabled);
  const [{ fetching }, updateTeamNotifySettings] = useMutation(updateTeamNotifySettingsMutation);
  const dispatch = useDispatch();

  const { data: team } = useGetTeam({ teamId: Number(teamId) });

  const enableTeamNotifyActionModal = () => {
    setTeamNotifySettings(true);
  };

  const disableTeamNotifyActionModal = () => {
    setTeamNotifySettings(false);
  };

  const setTeamNotifySettings = async (enabled: boolean) => {
    const updateTeamNotifyResult = await updateTeamNotifySettings(
      {
        id: teamsSettings.id,
        teamId: teamId,
        enabled
      },
      injectAuditTrailMetadata({
        teamName: `${team?.name} ${title} ${checked ? '(disabled)' : '(enabled)'}`,
        teamId: teamId.toString()
      })
    );

    if (updateTeamNotifyResult.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: getErrorMessageFromUrql(updateTeamNotifyResult?.error) }
      });

      // eslint-disable-next-line no-console
      console.error(updateTeamNotifyResult.error);
      apm.captureError(updateTeamNotifyResult.error);

      setChecked(!enabled);
      refetch();
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Update successfully'
    });
    setChecked(enabled);
    refetch();
    return;
  };

  const getTooltipTitle = () => {
    if (checked) {
      return `Disable ${title} notification`;
    }

    return `Enable ${title} notification`;
  };

  return (
    <NotificationSwitchBase
      title={`Notify me in case of an ${title}`}
      checked={checked}
      onEnable={enableTeamNotifyActionModal}
      onDisable={disableTeamNotifyActionModal}
      tooltipTitle={getTooltipTitle()}
      disabled={fetching}
      useProviderFeaturePlan={true}
      activeMessage={ENABLE_MESSAGE}
      disableMessage={DISABLE_MESSAGE}
    />
  );
}
