import { Dispatch, SetStateAction } from 'react';

import {
  Box,
  ClickAwayListener,
  Popper,
  Button,
  Typography,
  Paper,
  IconButton,
  Divider
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FiltersPopperProps } from 'oldComponents/FiltersPopper';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { DeployEnum, FilterTypeEnum, IdOptions, StatusOptions } from '..';
import { DeployFilter } from '../FilterOptions/Deploy';
import { ExternalIntegrationsOriginsFilter } from '../FilterOptions/ExternalIntegrationsOrigins';
import { IdsFilter } from '../FilterOptions/Ids';
import { OriginsFilter } from '../FilterOptions/Origins';
import { SeverityFilter } from '../FilterOptions/Severity';
import { StatusFilter } from '../FilterOptions/Status';

import { useStyles } from './styles';

export type PageFilters = {
  status: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
  severity: SeverityStatusEnum | 'all';
  deploy: DeployEnum | 'all';
  origins?: Origin[];
  externalIntegrationsOrigins?: ExternalIntegrationsOrigin[];
  ids?: IdOptions;
};

export type Origin = {
  value: string;
  label: string;
  type: string;
};

export type ExternalIntegrationsOrigin = {
  value: string;
  label: string;
};

export type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  handleClose: () => void;
  status: 'all' | StatusEnum | MultiFlowStatusEnum;
  setStatus: Dispatch<SetStateAction<StatusEnum | MultiFlowStatusEnum | 'all'>>;
  severity: 'all' | SeverityStatusEnum;
  setSeverity: Dispatch<SetStateAction<'all' | SeverityStatusEnum>>;
  origins: Origin[];
  setOrigins: Dispatch<SetStateAction<Origin[]>>;
  externalIntegrationsOrigins: ExternalIntegrationsOrigin[];
  setExternalIntegrationsOrigins: Dispatch<SetStateAction<ExternalIntegrationsOrigin[]>>;
  ids: IdOptions;
  setIds: Dispatch<SetStateAction<IdOptions>>;
  idsOptions?: IdOptions;
  hideStatus?: boolean;
  hideSeverity?: boolean;
  hideOrigins?: boolean;
  hideExternalIntegrationsOrigins?: boolean;
  hideIds?: boolean;
  statusOptions?: StatusOptions;
  originsQuery: string;
  deployment: 'all' | DeployEnum;
  setDeployment: Dispatch<SetStateAction<'all' | DeployEnum>>;
  type: FilterTypeEnum;
} & Pick<FiltersPopperProps, 'anchorEl'>;

export function Filters({
  anchorEl,
  onApplyFilters,
  onClearFilters,
  handleClose,
  status,
  setStatus,
  severity,
  setSeverity,
  origins,
  setOrigins,
  hideStatus = false,
  hideSeverity = false,
  hideOrigins = false,
  hideIds = true,
  statusOptions,
  originsQuery,
  deployment,
  setDeployment,
  type,
  hideExternalIntegrationsOrigins = true,
  setExternalIntegrationsOrigins,
  externalIntegrationsOrigins,
  ids,
  setIds,
  idsOptions
}: FiltersProps) {
  const classes = useStyles();

  const handleApplyFilters = ({
    newStatus,
    newSeverity,
    newOrigins,
    newDeployment,
    newExternalIntegrationsOrigins,
    newIds,
    close
  }: {
    newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
    newSeverity?: SeverityStatusEnum | 'all';
    newOrigins?: Origin[] | undefined;
    newDeployment?: DeployEnum | 'all';
    newExternalIntegrationsOrigins?: ExternalIntegrationsOrigin[] | undefined;
    newIds?: IdOptions | undefined;
    close?: boolean;
  }) => {
    const filters = {
      statusToUse: newStatus ?? status,
      severityToUse: newSeverity ?? severity,
      originsToUse: newOrigins ?? origins,
      deploymentToUse: newDeployment ?? deployment,
      externalIntegrationsOriginsToUse:
        newExternalIntegrationsOrigins ?? externalIntegrationsOrigins,
      idsToUse: newIds ?? ids
    };

    const {
      statusToUse,
      severityToUse,
      originsToUse,
      deploymentToUse,
      externalIntegrationsOriginsToUse,
      idsToUse
    } = filters;

    if (close) {
      handleClose();
      return;
    }

    onApplyFilters({
      status: statusToUse,
      severity: severityToUse,
      origins: originsToUse,
      deploy: deploymentToUse,
      externalIntegrationsOrigins: externalIntegrationsOriginsToUse,
      ids: idsToUse
    });
  };

  const handleClearFilters = () => {
    setStatus('all');
    setSeverity('all');
    setExternalIntegrationsOrigins([]);
    setOrigins([]);
    setIds([]);
    onClearFilters();
    handleClose();
  };

  const handleClosePopper = (event: any) => {
    if (event.target.localName === 'body') {
      event.preventDefault();
      return;
    }

    handleClose();
  };

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom"
      className={classes.popper}
      style={{ width: anchorEl?.getBoundingClientRect().width }}>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Paper className={classes.paper}>
          <Box className={classes.content}>
            <Box className={classes.dialogTitle}>
              <Typography variant="h4">Filters</Typography>

              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />

            <Box className={classes.selectContainer}>
              <Box display="flex" justifyContent="space-between" gridGap={12}>
                {type === FilterTypeEnum.Deploy ? (
                  <DeployFilter
                    deployment={deployment}
                    setDeployment={setDeployment}
                    handleApplyFilters={handleApplyFilters}
                  />
                ) : null}

                {type === FilterTypeEnum.Incident && !hideStatus ? (
                  <StatusFilter
                    status={status}
                    setStatus={setStatus}
                    handleApplyFilters={handleApplyFilters}
                    options={statusOptions!}
                  />
                ) : null}

                {type === FilterTypeEnum.Incident && !hideSeverity ? (
                  <SeverityFilter
                    severity={severity}
                    setSeverity={setSeverity}
                    handleApplyFilters={handleApplyFilters}
                  />
                ) : null}

                {!hideOrigins ? (
                  <OriginsFilter
                    origins={origins}
                    setOrigins={setOrigins}
                    handleApplyFilters={handleApplyFilters}
                    originsQuery={originsQuery}
                  />
                ) : null}

                {!hideIds ? (
                  <IdsFilter
                    ids={ids}
                    idsOptions={idsOptions}
                    setIds={setIds}
                    handleApplyFilters={handleApplyFilters}
                  />
                ) : null}

                {!hideExternalIntegrationsOrigins ? (
                  <ExternalIntegrationsOriginsFilter
                    origins={externalIntegrationsOrigins}
                    setOrigins={setExternalIntegrationsOrigins}
                    handleApplyFilters={handleApplyFilters}
                  />
                ) : null}
              </Box>
            </Box>

            <Box className={classes.buttonsContainer} mt={2}>
              <Button onClick={handleClearFilters} variant="outlined" color="primary">
                Clear
              </Button>
              <Button
                onClick={() => handleApplyFilters({ close: true })}
                variant="contained"
                color="primary">
                Apply
              </Button>
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}
