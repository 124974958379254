import { Box, CardContent, Chip, Divider, Paper, Typography, useTheme } from '@material-ui/core';

import { IncidentTags as IncidentTagsType } from 'hooks/queriesGraphQL/useGetIncidentTags';

import { useStyles } from './styles';

type IncidentTagsProps = {
  tags: IncidentTagsType[];
};

function IncidentTags({ tags }: IncidentTagsProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} variant="subtitle2">
          Tags
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />

        <Box display="flex" gridGap={theme.spacing()} mt={1}>
          {tags.map(tag => (
            <Box display="flex" gridGap={theme.spacing()} key={tag.id}>
              <Chip label={`${tag.name}:${tag.value}`} className={classes.chip} />
            </Box>
          ))}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default IncidentTags;
