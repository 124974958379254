import { ProvidersEnum } from 'hooks/queriesGraphQL/useGetProvider';

export const FormatConfig = (data: any, providerName?: ProvidersEnum) => {
  switch (providerName) {
    case ProvidersEnum.DiscordChannel:
      return {
        discordChannelWebhookUrl: data?.discordChannelWebhookUrl
      };
    case ProvidersEnum.Slack:
      return {
        slackIntegration: JSON.parse(data?.slackIntegration),
        slackWorkspace: data?.slackWorkspace,
        slackChannel: data?.slackChannel
      };
    case ProvidersEnum.Teams:
      return {
        teamsWebhookUrl: data?.teamsWebhookUrl
      };
    case ProvidersEnum.GoogleChat:
      return {
        gchatWebhookUrl: data?.gchatWebhookUrl
      };
    case ProvidersEnum.WebHook:
      return {
        webhookUrl: data?.webhookUrl,
        webhookRetryFailEmail: data?.webhookRetryFailEmail,
        webhookRetry: data?.webhookRetry
      };
    case ProvidersEnum.Telegram:
      return {
        telegramId: data?.telegramId
      };
    case ProvidersEnum.Mail:
      return {
        email: data?.email
      };
  }
};
