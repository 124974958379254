import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { ButtonSimple } from 'componentsV4/Button';
import ModalDeleteSchedule from 'componentsV4/ModalDeleteSchedule';
import actions from 'redux/actions';
import axios from 'redux/axios';

import FormEdit from './FormEdit';

const DialogEdit = ({ open, onClose, eventId, refetchSchedules, permissions, scheduleData }) => {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const { id: teamId } = useParams();
  const [groupId, setGroupId] = React.useState(null);
  const [groupName, setGroupName] = React.useState(null);
  const [color, setColor] = React.useState(null);
  const [uid, setUid] = React.useState(null);

  const [saveOption, setSaveOption] = React.useState('this');

  const [dataSelected, setDataSelected] = React.useState({ start: '', end: '' });
  const [timeSelected, setTimeSelected] = React.useState({ start: '', end: '' });

  const { data, isLoading } = useQuery(['callRotationSchedule', eventId], () =>
    axios.get(`/callrotation/schedule/${eventId}`).then(response => response.data.data)
  );

  React.useEffect(() => {
    if (data) {
      setColor(data.color);
      setUid(data.uid);
      setDataSelected({
        start: dayjs(data.startDate).format('YYYY-MM-DD'),
        end: dayjs(data.endDate).format('YYYY-MM-DD')
      });
      setTimeSelected({
        start: dayjs(data.startDate).format('HH:mm'),
        end: dayjs(data.endDate).format('HH:mm')
      });
      setGroupId(data.groupId);

      const team = scheduleData?.find(team => team.groupId === data.groupId);
      if (team) setGroupName(team.group?.name || null);
    }
  }, [data, scheduleData]);

  const [onSaveEvent] = useMutation(data => axios.put(`/callrotation/schedule/${eventId}`, data), {
    onSuccess: () => {
      onClose();
      queryCache.invalidateQueries('callRotationSchedules');
      refetchSchedules();
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Event updated successfully'
      });
    },
    onError: error => {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: error,
        ga: { category: 'ERROR' }
      });
    }
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleSave = () => {
    const startDate = dayjs(dataSelected.start + ' ' + timeSelected.start).toDate();

    const endDate = dayjs(dataSelected.end + ' ' + timeSelected.end).toDate();

    onSaveEvent({
      id: eventId,
      uid,
      color,
      startDate,
      endDate,
      saveOption,
      groupId
    });
  };

  if (!isLoading) {
    return (
      <>
        <Box mb={2}>
          <Typography variant="h3" gutterBottom>
            Edit schedule
          </Typography>
        </Box>
        <FormEdit
          refetchSchedules={refetchSchedules}
          eventId={eventId}
          timeSelected={timeSelected}
          setTimeSelected={setTimeSelected}
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
          saveOption={saveOption}
          setSaveOption={setSaveOption}
          color={color}
          setColor={setColor}
          groupId={groupId || data.groupId}
          setGroupId={setGroupId}
          teamId={teamId}
          permissions={permissions}
          setGroupName={setGroupName}
        />
        <Box p={0.5} mt={1} display="flex" justifyContent="flex-end">
          <Box mr={1} clone>
            <ButtonSimple onClick={onClose} text="Cancel" variant="outlined" color="primary" />
          </Box>

          <Box mr={1} clone>
            <ButtonSimple
              onClick={openDeleteModal}
              startIcon={<DeleteIcon />}
              text="Delete"
              variant="outlined"
              color="primary"
              disabled={!permissions.deleteCallRotation}
            />
          </Box>

          <ButtonSimple
            onClick={handleSave}
            color="primary"
            variant="contained"
            text="Save"
            disabled={!permissions.editCallRotation}
          />
        </Box>
        <ModalDeleteSchedule
          open={showDeleteModal}
          handleClose={() => {
            setShowDeleteModal(false);
            onClose();
          }}
          scheduleId={Number(eventId)}
          scheduleUid={uid}
          groupName={groupName}
        />
      </>
    );
  }

  return null;
};

export default DialogEdit;
