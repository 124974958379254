import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    paddingBottom: theme.spacing(12)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: theme.spacing(2, 2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    height: '100%',
    width: '100%',
    padding: 0
  },
  titleCard: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  dividerSections: {
    backgroundColor: theme.palette.text.hint,
    opacity: '30%'
  },
  chip: {
    backgroundColor: '#DEE9FF',
    color: '#1D5ED6'
  },
  noData: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1
  }
}));
