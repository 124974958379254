import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertSentryGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Sentry',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/alerts/v1/sentry/${integrationUid}?token=${token}`,
    snippetWarning: {
      message:
        'There is no need for a payload to integrate the platform with Sentry, only the URL is required. If you wish to classify the severity of an incident/alert, check out our ',
      linkHref:
        'https://docs.elven.works/guia-configuracao-do-external-service-alert-sentry-na-elven-platform/',
      linkText: 'documentation.'
    }
  };
};
